import { Component } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-legend-panel',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './legend-panel.component.html',
  styleUrl: './legend-panel.component.scss'
})
export class LegendPanelComponent {

}
