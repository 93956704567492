<div class="DragDropFile" [ngClass]="showMarkAsComplete ? 'disable-upload' : ''" >
    <label class="file__choose body-b1" for="upload-file" fileDragDrop (filesChangeEmiter)="selectFiles($event, 'drag')">
        Drag and drop file here
        <span class="uploadBtn section-title">
            <svg-icon class="df-c" src="assets/icons/upload.svg"></svg-icon>
            Select file
        </span>  
        <input type="file" id="upload-file" multiple accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf,.msg" (change)="selectFiles($event, 'upload')" />
    </label>
    <p class="caption">Supported file format: PDF, DOCX, XLSX, JPG, PNG or MSG. Max file size: 10MB.</p>
</div>
<p *ngIf="documents?.length > 0 || progressInfos.length > 0 || documentLoading" class="body-b UploadedTitle">Uploaded documents:</p>
<div [ngClass]="['approve','review'].includes(uploadScreen) ? 'scroll documentListing' : ''">
@for (progressInfo of progressInfos.slice().reverse(); let i = $index; track progressInfo) {
    <div class="progress-info">
    @if (progressInfo?.status === 'Uploaded') {
        <div class="LinkMnHld">
            <div class="LinkMnInner">
                <div class="LinkMnInside">
                    @if (fileNameTooltipVisibility?.[progressInfo?.docNumber]) {
                    <snl-tooltip [position]="'top'">
                        <a class="fileNameLink" href="javascript:void(0)" id="{{progressInfo?.docNumber}}" (click)="download(progressInfo?.docId)">{{progressInfo.fileName}}</a>
                        <div tooltipContent class="TpCustomization fixed240"> 
                            <div class="section-title">
                                {{progressInfo?.fileName}}
                            </div>
                        </div>
                    </snl-tooltip>
                    }
                    @else {
                    <a class="fileNameLink" href="javascript:void(0)" id="{{progressInfo?.docNumber}}" (click)="download(progressInfo?.docId)">{{progressInfo.fileName}}</a>
                    }
                    <span class="caption NameFleMb">{{ extractFileSize(progressInfo.fileSize) }}</span>
                </div>
            </div>
        </div>
        @if (deletingDocumentId === progressInfo?.docId || downloadingDocumentId === progressInfo?.docId) {
        <svg-icon class="df-c rotating-icon" src="assets/icons/loader-icon.svg"></svg-icon>
        }
        @else{
        <em [ngClass]="showMarkAsComplete ? 'disable-delete' : ''">
            <svg-icon (click)="deleteFile(progressInfo?.docId, true, true)" class="df-c trashIcn" src="assets/icons/trash.svg"></svg-icon>
        </em>
        }
    }
    @else if (progressInfo?.status === 'Failed') {
        <div class="LinkMnHld">
            <div class="LinkMnInner">
                <div class="LinkMnInside">@if (fileNameTooltipVisibility?.[progressInfo?.docNumber]) {
                    <snl-tooltip [position]="'top'">
                        <a class="fileNameLink" href="javascript:void(0)" id="{{progressInfo?.docNumber}}" (click)="download(progressInfo?.docId)">{{progressInfo.fileName}}</a>
                        <div tooltipContent class="TpCustomization fixed240"> 
                            <div class="section-title">
                                {{progressInfo?.fileName}}
                            </div>
                        </div>
                    </snl-tooltip>
                    }
                    @else {
                    <a class="fileNameLink" href="javascript:void(0)" id="{{progressInfo?.docNumber}}" (click)="download(progressInfo?.docId)">{{progressInfo.fileName}}</a>
                    }
                    <span class="caption NameFleMb">{{ extractFileSize(progressInfo.fileSize) }}</span>
                </div>
                <span class="caption HelpMsgMn">
                    <svg-icon class="df-c" src="assets/icons/error_disabled.svg"></svg-icon> 
                    Error - {{message[progressInfos.length - i - 1]}}
                </span>
            </div>
        </div>
        <em [ngClass]="showMarkAsComplete ? 'disable-delete' : ''">
            <svg-icon (click)="deleteFile(progressInfo?.docId, true)" class="df-c trashIcn" src="assets/icons/trash.svg"></svg-icon>
        </em>
    }
    @else {
        <div class="progressInfo">
            <div class="FileNameValue body-b1"> 
                <span class="FileNameValueInnr">
                    <span class="body-b">{{ progressInfo.fileName }}</span> 
                    <span class="caption NameFleMb">{{ extractFileSize(progressInfo.fileSize) }}</span>
                </span>
            </div>
            <div class="progressHld">
                <div class="progress">
                    <div class="{{progressInfo?.status == 'Failed' ? 'first-progress-failed':'first-progress'}}">
                        <div [ngStyle]="{ 'width.%': progressInfo?.value }" class="{{progressClass}}"></div>
                    </div> 
                </div>
                <span class="caption PercetageNumb">{{ progressInfo?.value }}%</span>
            </div>
            <span class="caption UploadingTxt">Uploading...</span> 
        </div>
    }
    </div> 
}

@if (documentLoading) {
    <div class="StoltSkeletonUpld">
        <div class="Stolt-Skeleton"></div>
        <div class="Stolt-Skeleton"></div>
    </div>
}
@else {
    @for (document of documents; track $index) {
    <div class="progress-info">
        <div class="LinkMnHld">
            <div class="LinkMnInner">
                <div class="LinkMnInside">
                    @if (fileNameTooltipVisibility?.[document?.docNumber]) {
                    <snl-tooltip [position]="'top'">
                        <a class="fileNameLink" href="javascript:void(0)" (click)="download(document?.docId)">{{uploadService.extractFileName(document?.fileName)}}</a>
                        <div tooltipContent class="TpCustomization fixed240"> 
                            <div class="section-title">
                                {{uploadService.extractFileName(document?.fileName)}}
                            </div>
                        </div>
                    </snl-tooltip>
                    }
                    @else {
                    <a class="fileNameLink" href="javascript:void(0)" id="{{document?.docNumber}}" (click)="download(document?.docId)">{{uploadService.extractFileName(document?.fileName)}}</a>
                    }
                    <span class="caption NameFleMb">{{ extractFileSize(document.fileSize) }}</span>
                </div>
            </div>
        </div>
        @if (deletingDocumentId === document?.docId || downloadingDocumentId === document?.docId) {
        <svg-icon class="df-c rotating-icon" src="assets/icons/loader-icon.svg"></svg-icon>
        }
        @else{
        <em [ngClass]="showMarkAsComplete ? 'disable-delete' : ''">
            <svg-icon (click)="deleteFile(document?.docId, false, true)" class="df-c trashIcn" src="assets/icons/trash.svg"></svg-icon>
        </em>
        }
    </div>
    }
}
</div>