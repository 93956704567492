import { Injectable } from '@angular/core';
import { TYPEOFCOA } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  public typeOfCOA: any = TYPEOFCOA;
  public filterItems(allCCDData: any, selectedFilters: any) {
    return allCCDData.filter((item: any) => {
      const customer = this.filterBySelected(item.customer, selectedFilters.customer, 'name');
      const lob = this.filterBySelected(item.lob, selectedFilters.lob, 'name', true); // For array (includes)
      const owner = this.filterBySelected(item.owner, selectedFilters.owner, 'name');
      const coaType = this.filterBySelected(this.typeOfCOA[item.coaType], selectedFilters.coaType, 'name');
  
      return customer && lob && owner && coaType;
    });
  }
  
  private filterBySelected(
    itemValue: any,
    selectedFilter: any[],
    key: string,
    useIncludes: boolean = false
  ): boolean {
    return selectedFilter.reduce((prev: boolean, curr: any, currentIndex: number) => {
      const condition = useIncludes
        ? itemValue?.includes(curr[key])
        : itemValue === curr[key];
  
      if (condition) {
        return true;
      } else if (currentIndex === 0) {
        return false;
      } else {
        return prev;
      }
    }, true);
  }  

}
