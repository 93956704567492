import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Tabs } from '../../interfaces/tabs-interface';
import { IconModule, PageTabModule } from 'snl-complib';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule, IconModule, PageTabModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild('tabMenuRef', { static: true }) tabMenuRef!: ElementRef;
  @Input() tabs: Tabs[] = [];
  @Input() selectedTab:string | undefined = 'active';
  @Output() onSelect = new EventEmitter();
  
  public role:string = '';

  viewModel: { tabContainerWidth: number; showLeftChevron: boolean; showRightChevron: boolean; scrollPosition: number; } = {
    tabContainerWidth: 0,
    showLeftChevron: false,
    showRightChevron: false,
    scrollPosition: 0
  };

  observerTabMenuView!: ResizeObserver | any;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService
    ) { }

  onSelectTab(tab: Tabs, i: number): void {
    this.selectedTab = tab?.value;
    this.tabs.forEach((tab, index) => i == index ? Object.assign(tab, { active: true }) : Object.assign(tab, { active: false }));
    const selectedTab = { label: tab.label, value: tab?.value };
    this.onSelect.emit(selectedTab);
  }

  ngOnInit(): void {
    this.authService.getBackendUserData().subscribe((data: any) => {
      this.role = data?.role;
    });

    const divElement: HTMLElement = this.tabMenuRef?.nativeElement;
    if (divElement && !this.observerTabMenuView) {
      this.observerTabMenuView = new ResizeObserver((entries) => {
        for (const _ of entries) {
          this.updateChevronVisibility();
          this.cdr.detectChanges();
        }
      });
      this.observerTabMenuView.observe(divElement)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('tabs') && changes['tabs']?.currentValue) {
      this.viewModel = {
        tabContainerWidth: 0,
        showLeftChevron: false,
        showRightChevron: false,
        scrollPosition: 0
      };
      this.cdr.detectChanges();
      this.updateChevronVisibility();
    }
  }

  ngAfterViewInit(): void {
    this.updateChevronVisibility();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.observerTabMenuView?.disconnect();
    this.observerTabMenuView = undefined;
  }

  updateChevronVisibility(): void {
    this.cdr.detectChanges();
    setTimeout(() => {
      const width = this.tabMenuRef?.nativeElement?.clientWidth;
      this.viewModel.showLeftChevron = (this.tabMenuRef?.nativeElement?.scrollLeft !== 0);
      this.viewModel.showRightChevron = Math.round(+this.tabMenuRef?.nativeElement?.scrollLeft) !== this.tabMenuRef?.nativeElement?.scrollWidth - width;
    });
  }

  onScroll(event: any): void {
    this.updateChevronVisibility();
    event.preventDefault();
  }

  navBackward(): void {
    const content = this.tabMenuRef?.nativeElement;
    const width = this.tabMenuRef.nativeElement.clientWidth;
    const pos = content.scrollLeft - width;
    content.scrollLeft = pos <= 0 ? 0 : pos;
    this.updateChevronVisibility();
  }

  navForward(): void {
    const content = this.tabMenuRef?.nativeElement;
    const width = this.tabMenuRef.nativeElement.clientWidth;
    const pos = content.scrollLeft + width;
    const lastPos = content.scrollWidth - width;
    content.scrollLeft = pos >= lastPos ? lastPos : pos;
    this.updateChevronVisibility();
  }
}