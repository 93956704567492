<div class="legend-title">
    <div class="legend-text">Legend:</div>
    <div class="tag-text"><svg-icon src="assets/icons/no_action_taken.svg"></svg-icon><span>No action</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/action_taken.svg"></svg-icon><span>Action taken</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/completed.svg"></svg-icon><span>Completed</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/no_document.svg"></svg-icon><span>No document</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/document_added.svg"></svg-icon><span>Document added</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/no_notes.svg"></svg-icon><span>No notes</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/notes_added.svg"></svg-icon><span>Notes added</span></div>
    <div class="tag-text"><svg-icon src="assets/icons/na.svg"></svg-icon><span>Not applicable</span></div>
</div>