import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  upload(fileData: { attachment: { fileName: String, content: String }}): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(`${environment.BASE_URL}upload-files`, fileData, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteFile(fileData:any) {
    return this.http.post(`${environment.BASE_URL}delete-file`, fileData);
  }
  
  downloadFile(docId:string) {
    return this.http.get(`${environment.BASE_URL}download-files?docId=${docId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
