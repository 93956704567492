import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, DatePickerModule, DropdownModule, InputModule, LinkModule, ModalModule, PageTabModule, PageTitleModule, SwitchModule, TableModule } from 'snl-complib';

@Component({
  selector: 'app-manage-users',
  standalone: true,
  imports: [CommonModule,SvgIconComponent, DropdownModule,DatePickerModule,InputModule,ButtonModule,ActionPanelModule,PageTitleModule,LinkModule,PageTabModule,SwitchModule,TableModule,ModalModule	
  ],
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent {
  open: boolean = false;
  DeleteUserModel: boolean = false;
}
