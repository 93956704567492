export const ROLES = {
    'Manager' : "1",
    'Owner' : "2",
    'DepartmentUser' : "3"
}
export const TYPEOFCOA = {
    NEW_COA: 'New COA',
    NEW_RATES_AND_TERMS: 'New rates and terms',
    FINANCIAL_NEGOTIATIONS: 'Financial negotiations',
    OPTIONAL_YEARS: 'Optional years',
}

export const VALIDATION_MESSAGE = Object.freeze({
    required: 'This field is a required.'
})
export const customizedValidationMessages = (type: string, num:number) => {
    let message = '';
    switch (type) {
        case 'minlength':
            message = `Please enter at least ${num} characters.`;
            break;
        case 'maxlength':
            message = `Value must not be greater than ${num} characters long.`;
            break;
        default:
            break;
    }
    return `${message}`;
}