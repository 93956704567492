import { Injectable } from '@angular/core';
import { IToastConfig, ToastService } from 'snl-complib';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private toastService: ToastService) { }

  openToastCcd(type: any, message: any, title = '') {
    const toastConfig: IToastConfig = {
      type: type,
      message: message,
      addTitle: true,
      addProgressBar: true,
      title: title,
      timeout: 5000,
      position: 'bottom-right',
      multiAlerts: true
    };
    this.toastService.open(toastConfig);
  }
}
