import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule, CheckboxModule, DatePickerModule, FileUploadModule, InputModule, ModalModule } from 'snl-complib';
import { UploadFilesComponent } from 'src/app/components/upload-files/upload-files.component';
import { CcdService } from 'src/app/services/ccd.service';
import { ValidationService } from 'src/app/services/validation.service';
import { Toasts } from 'src/app/constant/toast.messages';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-approve-popup',
  standalone: true,
  imports: [ModalModule, CheckboxModule, InputModule, FileUploadModule, DatePickerModule, ButtonModule, ReactiveFormsModule, NgClass, UploadFilesComponent],
  templateUrl: './approve-popup.component.html',
  styleUrl: './approve-popup.component.scss'
})
export class ApprovePopupComponent {
  disableSubmitButton: boolean = true;
  modalSettings: any;
  @Input() open: boolean = false;
  @Input() ccdData: any = [];
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();
  public approveCcdForm!: FormGroup;
  public setApprovalDate: Date = new Date();
  public maxApprovalDate: Date = new Date();
  public submitLoading: boolean = false;
  public uploadedDocuments: any = [];

  constructor(
    private ccdService: CcdService,
    private toasterService: ToasterService,
    private validationService: ValidationService
  ) { }

  ngOnInit() {
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
    this.createApproveCcdForm();
    this.setApprovalDateFunc();
  }

  setApprovalDateFunc() {
    const today = new Date();
    this.maxApprovalDate.setDate(today.getDate());
    this.setApprovalDate.setDate(today.getDate());
    this.approveCcdForm.patchValue({
      approvedAt: this.ccdService.getLocalISOTime(new Date(this.setApprovalDate)),
      approvedAtOffset: new Date().getTimezoneOffset()
    });
  }

  selectedApprovalDate(event: any) {
    this.approveCcdForm.patchValue({ approvedAt: this.ccdService.getLocalISOTime(event), approvedAtOffset: new Date().getTimezoneOffset() });
  }

  createApproveCcdForm() {
    this.approveCcdForm = new FormGroup({
      isApprovedOnBehalf: new FormControl(false),
      approverName: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      approvalNotes: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      approvedAt: new FormControl(''),
      approvedAtOffset: new FormControl('')
    });
  }

  onBehalfOfClick(event: any) {
    this.approveCcdForm.patchValue({ isApprovedOnBehalf: event.target.checked });

    const approverName = this.approveCcdForm.get('approverName');
    if (event.target.checked) {
      if (approverName) {
        approverName.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(100)]);
      }
    }
    else {
      this.approveCcdForm.patchValue({ approverName: '' });
      if (approverName) {
        approverName.setValidators([Validators.minLength(3), Validators.maxLength(100)]);
      }
    }
    approverName?.updateValueAndValidity();
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.approveCcdForm);
    return errorMsg;
  }

  setUploadedDocuments(event: any) {
    this.uploadedDocuments.push(event?.docId);
  }
  @ViewChild(UploadFilesComponent) uploadFilesComponentRef!: UploadFilesComponent;
  closeModalPopUp() {
    this.onButtonClick.emit({})
    const uploadComponent = this.uploadFilesComponentRef;
    if (uploadComponent) {
      this.uploadedDocuments.forEach((docId: string) => {
        uploadComponent.deleteFile(docId, true, true, true);
      });
    }
  }

  trimFormFields() {
    Object.keys(this.approveCcdForm.controls).forEach(key => {
      const control = this.approveCcdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  updateNoteInFormValues() {
    return { ...this.approveCcdForm.value, approvalNotes: `${this.approveCcdForm.value?.isApprovedOnBehalf ? '<p><strong>Approving on behalf of ' + this.approveCcdForm.value?.approverName + '</strong><p>' : ''} ${this.approveCcdForm.value?.approvalNotes}` };
  }

  submitApproveCcdForm() {
    if (this.approveCcdForm.valid) {
      this.trimFormFields();
      const reqData = {
        ccdNo: this.ccdData?.ccdNo,
        approveData: this.updateNoteInFormValues(),
      };
      this.submitLoading = true;
      this.ccdService.setReviewScreenAutoSaveMessage('saving');
      this.ccdService.approveCcd(reqData).subscribe({
        next: () => {
          this.submitLoading = false;
          this.onButtonClick.emit({
            isCcdApproved: true
          });
          this.ccdService.setReviewScreenAutoSaveMessage('saved');
        },
        error: (error) => {
          this.submitLoading = false;
          this.ccdService.setReviewScreenAutoSaveMessage('not-saved');
          if (error?.status === 423) {
            this.toasterService.openToastCcd(Toasts.Types.Warning, error?.error?.reason);
          } else if (error?.status === 424) {
            this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
          } else {
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          }
          console.error('Error approving ccd:', error);
        }
      });
    }
  }
}
