import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CcdService {

  constructor(
    private http: HttpClient
  ) { }

  fetchUser() {
    return this.http.get(`${environment.BASE_URL}fetch-user`);
  }
  
  fetchCCDList(status:any) {
    return this.http.post(`${environment.BASE_URL}fetch-dashboard-data`,status);
  }
  
  fetchCCDCount() {
    return this.http.get(`${environment.BASE_URL}fetch-contract-count`);
  }
  
  updateContractStatus(data:any) {
    return this.http.post(`${environment.BASE_URL}update-contract-status`, data);
  }
  
  initiateUpcomingContract(data:any) {
    return this.http.post(`${environment.BASE_URL}initiate-upcoming-contract`, data);
  }
  
  fetchLobList() {
    return this.http.get(`${environment.BASE_URL}fetch-lob-list`);
  }
  
  fetchManagersData() {
    return this.http.get(`${environment.BASE_URL}fetch-managers-data`);
  }
  
  fetchCcdReference(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-reference`, data);
  }
  
  createCcd(data:any) {
    return this.http.post(`${environment.BASE_URL}create-ccd-draft`, data);
  }
  
  fetchCcdData(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-data`, data);
  }
  
  createCcdStep2(data:any) {
    return this.http.post(`${environment.BASE_URL}create-departments-and-notes`, data);
  }
  
  fetchCcdNotes(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-notes`, data);
  }
  
  submitCcd(data:any) {
    return this.http.post(`${environment.BASE_URL}update-ccd-to-active`, data);
  }

  updateAuditLogs(data:any) {
    return this.http.post(`${environment.BASE_URL}add-audit-logs`, data);
  }
  
}
