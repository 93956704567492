import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { NavigationModule } from 'snl-complib';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { CcdService } from 'src/app/services/ccd.service';
import { StorageService } from 'src/app/services/storage.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ROLES } from 'src/app/constant/constant';
import { catchError, from, of, switchMap } from 'rxjs';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-left-menu',
  standalone: true,
  imports: [NavigationModule],
  templateUrl: './left-menu.component.html',
})
export class LeftMenuComponent {
  @Input() navigationUrl: any;
  @Input() displayName: any;
  menuOptions: any = '';
  signOutOption: any = '';
  isCollapsed: any;
  splitted: any;
  userOption: any = {
    displayName: '',
    designation: '',
  };
  environmentType: any = '';
  bottomOption: any;
  role: any;
  eventValue: any;
  heading: any;
  open: boolean = false;
  deleteMsg: any;
  isSubjectValue: any;
  userData: any;
  constructor(
    private router: Router,
    private navService: NavigationService,
    private renderer: Renderer2,
    private authService: AuthService,
    private msalService: MsalService,
    private ccdService: CcdService,
    private encryptionService : EncryptionService,
    private storageService: StorageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit(): void {
    this.environmentType = environment.type;
    this.loadMenuOption(this.navigationUrl);
    this.getUserData();
  }

  getUserData() {
    this.authService.getUserData().subscribe((profile: any) => {
      if (profile?.displayName) {
        let displayName = profile?.displayName.split(' ');
        const userInitials = displayName[displayName.length - 1].replace(/[(|)]/g, '').slice(0, 3);
        const name: any = displayName.splice(0, displayName.length - 1).join(' ');
        const userOption = {
          displayName: name,
          designation: profile?.jobTitle,
          initials: userInitials
        };
        if(profile?.displayName.toLowerCase().includes('bti-test')){
          userOption.displayName = profile?.displayName;
        }
        this.userOption = userOption;
        this.googleAnalyticsService.logEvent("page_load", 'user_login', 'page_load', this.router.url);
      }
    })
    
    this.authService.getBackendUserData().subscribe((profile: any) => {
      this.userData = profile;
      this.loadMenuOption(this.navigationUrl);
    });
  }

  async loadMenuOption(navigationUrl: string) {
    this.menuOptions = [
      {
        iconName: 'fa-solid fa-border-all',
        displayName: 'Dashboard',
        selected: navigationUrl == '' ? true : this.menuselected(navigationUrl, '') || this.menuselected(navigationUrl, 'dashboard'),
        path: 'dashboard',
      },
    ];
    if([ROLES['Manager'],ROLES['Owner']].includes(this.userData?.role) && this.userData?.isAdmin){
      this.bottomOption = [
        {
          iconName: 'fa fa-users',
          displayName: 'User management',
          selected: this.menuselected(navigationUrl,'manage-users'),
          path: 'manage-users',
        }
      ]
    }
    this.signOutOption = {
      iconName: 'fa-solid fa-sign-out',
      displayName: 'Sign Out',
      selected: false,
      path: 'logout',
    };
  }

  public menuselected(select: string, menu: string) {
    if (select == menu) {
      return true;
    }
    return false;
  }

  public userLogout() {
    this.msalService.logoutRedirect().pipe(
    switchMap(() => {
      // Clear local storage
      localStorage.clear();
      
      // Use fetch with keepalive for logging
      const logData = { type: 'logout' }; // Your log data object
      const logUrl = `${environment.BASE_URL}add-audit-logs`;
      const match = this.authService.UserObj.displayName.match(/\(([^)]+)\)/);
      const userInitials = match ? match[1] : this.authService.UserObj.displayName;
      const userInfo: any = {
        userId: this.authService.UserObj.id,
        userEmail: this.authService.UserObj.userPrincipalName,
        userName: userInitials,
        name: this.authService.UserObj.displayName,
      };
      const encryptedUserInfo = this.encryptionService.encryptionAES(JSON.stringify(userInfo));
      const fetchPromise = fetch(logUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'userAuth': encryptedUserInfo,
        },
        body: JSON.stringify(logData),
        keepalive: true,
      })
      .then(() => true)
      .catch(() => false);
      return from(fetchPromise);
    })
  ).subscribe({});
  }

  updateLogs() {
    this.ccdService.updateAuditLogs({ type: 'logout' }).subscribe((response) => {
      this.storageService.putDataInStorage('loggedIn', 'false');
    });
  }

  public selectedOption(event: any) {
    if (event.option && event.option.path == 'logout') {
      this.userLogout();
    } else if (event.option.path && event.option.path != 'logout') {
      this.loadMenuOption(event.option.path);
      this.router.navigate([event.option.path]);
    }
  }

  public navigationCollapse(event: any) {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsed
      ? this.renderer.addClass(this.document.body, 'leftbar-collapsed')
      : this.renderer.removeClass(this.document.body, 'leftbar-collapsed');
    this.navService.isNavigationCollapseSet(this.isCollapsed);
  }
}
