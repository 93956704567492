<snl-modal class="CommnModalChanges ApproveCCDMdl" (modalClose)="closeModalPopUp()" [settings]="modalSettings" [type]="'small'" [open]="open"> 
    <div slot="heading">Approve CCD</div> 
    <div slot="headingDescription">
        Please enter approval note to approve CCD
    </div>
    <div slot="modalContent">
        <form [formGroup]="approveCcdForm">
            <ul>
                <li class="ApproverCheckBx">
                    <p class="body-b">Approver</p>
                    <p class="section-title">{{ccdData?.manager}}</p>
                    <snl-checkbox
                     [isDisable]="false"
                     [checked]="false"
                     [intermediate]="false"
                     [id]="'checkbox-btn-sample'"
                     [name]="'Checkbox'"
                     [value]="'sample'"
                     (onClick)="onBehalfOfClick($event)"
                     >
                     Approving on behalf of
                     </snl-checkbox>

                     @if (approveCcdForm.value?.isApprovedOnBehalf) {
                     <div class="attachedSerach"> 
                        <snl-input
                            [value]="''"
                            [label]="'Name/ Initial'" 
                            formControlName="approverName"
                            [errorMessage]="showError('approverName')"
                        ></snl-input>
                     </div>
                     <div class="AddAttachment">
                        <p class="body-b1">Add attachment (optional)</p>
                        <app-upload-files [uploadScreen]="'approve'" [ccdNo]="ccdData?.ccdNo" [department]="'MANAGEMENT'" (uploadedDocuments)="setUploadedDocuments($event)"></app-upload-files>
                    </div>
                    }
                </li>
                <li><snl-date-picker-dropdown
                    datePickerType="date"
                    [max]="maxApprovalDate"
                    [startMonthDate]="setApprovalDate"
                    (selectedDate)="selectedApprovalDate($event)"
                    ></snl-date-picker-dropdown></li>
                <li><snl-input [label]="'Approval note'" formControlName="approvalNotes" [errorMessage]="showError('approvalNotes')"></snl-input></li>
            </ul>
        </form>
    </div> 
    <div slot="modalFooter">
      <snl-button size="large" (click)="submitApproveCcdForm()" [disabled]="!this.approveCcdForm.valid" class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton':submitLoading}">Submit</snl-button>
      <snl-button size="large" type="secondary" (click)="closeModalPopUp()">Cancel</snl-button>
    </div>
</snl-modal>