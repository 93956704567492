import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, filter, switchMap, take, tap, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { CcdService } from '../ccd.service';
import { StorageService } from '../storage.service';
import { EncryptionService } from '../encryption.service';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';

const handleError = (err: any) => {
  if (err instanceof HttpErrorResponse) {
    if (err.status === 401) {
      console.error('Unauthorized request:', err);
    } else {
      console.error('HTTP error:', err);
    }
  } else {
    console.error('An error occurred:', err);
  }
  return throwError(() => err);
};

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  let authReq: any = req;
  const authService = inject(AuthService);
  const encryptionService = inject(EncryptionService);
  const ccdService = inject(CcdService); // Inject CcdService
  const storageService = inject(StorageService);

  // Define the fetch-user API endpoint
  const fetchUserApiEndpoint = `${environment.BASE_URL}fetch-user`;

  if (req.url.startsWith(environment.BASE_URL) && Object.keys(authService.UserObj).length > 0) {
    if (authReq?.url !== environment.BASE_URL + 'add-audit-logs') {
      const currentDate = new Date().toISOString().split('T')[0];
      if (storageService.getDataFromStorage('loggedIn') === 'false' ||
        !storageService.getDataFromStorage('loggedIn') ||
        storageService.getDataFromStorage('lastLoginDate') !== currentDate) {
        updateLogs(ccdService, storageService, currentDate);
      }
    }

    const match = authService.UserObj.displayName.match(/\(([^)]+)\)/);
    const userInitials = match ? match[1] : authService.UserObj.displayName;
    const userInfo: any = {
      userId: authService.UserObj.id,
      userEmail: authService.UserObj.userPrincipalName,
      userName: userInitials,
      name: authService.UserObj.displayName,
    };

    // Check if the request is for the fetch-user API
    if (req.url === fetchUserApiEndpoint) {
      // Proceed with the request immediately for the fetch-user API
      const encryptedUserInfo = encryptionService.encryptionAES(JSON.stringify(userInfo));
      authReq = req.clone({
        setHeaders: {
          userAuth: encryptedUserInfo,
          //authorization: 'Bearer ' + authService.getAccessToken()
        }
      });
      return next(authReq).pipe(catchError(handleError));
    }

    // If the backend userdata is not fetched, wait for it to become available
    return authService.getBackendUserData().pipe(
      filter((backendUserData) => !!backendUserData), // Wait until a non-null backend userdata is emitted
      take(1), // Take the first emitted value
      switchMap((backendUserData) => {
        if (backendUserData?.role) {
          userInfo['userRole'] = backendUserData.role;
        }
        const encryptedUserInfo = encryptionService.encryptionAES(JSON.stringify(userInfo));
        // Clone the request and add the authorization header
        authReq = req.clone({
          setHeaders: {
            userAuth: encryptedUserInfo,
            //authorization: 'Bearer ' + authService.getAccessToken()
          }
        });
        return next(authReq).pipe(catchError(handleError));
      })
    );
  }
  return next(authReq);
};

function updateLogs(ccdService: CcdService, storageService: StorageService, currentDate: any) {
  storageService.putDataInStorage('lastLoginDate', currentDate);
  storageService.putDataInStorage('loggedIn', 'true');
  ccdService.updateAuditLogs({ type: 'login' }).subscribe({
    error: () => {
      storageService.removeDataFromStorage('lastLoginDate')
      storageService.removeDataFromStorage('loggedIn')
    }
  });
}