<section class="DetailsWrapMain">
    <!-- Left Card CCD Details  -->
    <div class="InfoCardLftHolder">
        <form [formGroup]="ccdForm">
            <div class="InfoCardLft">
                <h2 class="sub-heading-s1 star-required">
                    CCD details
                    <span class="note">
                        <span>*</span>
                        <span>All fields are required</span>
                    </span>
                </h2>
                <div class="FieldArea">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        @if (coaTypeObj.length > 0 && coaTypeOptions.length > 0) {
                        <snl-dropdown
                            [label]="'Type of COA *'"
                            [buttonPrimary]="'Apply'"
                            [buttonSecondary]="'Reset'"
                            [options]="coaTypeOptions"
                            [multiple]="false"
                            (selectedValues)="applySelectedCoaType($event)"
                            (resetDropdown)="resetCoaType()"
                            [preSelectedItems]="coaTypeObj"
                            [disabled]="ccdRes?.coaType === 'NEW_COA'"
                        ></snl-dropdown>
                        }
                    </div>

                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-date-picker-dropdown
                        datePickerType="date"
                        title="Due date *"
                        [min]="minDueDate"
                        [max]="maxDueDate"
                        [startMonthDate]="setDueDate"
                        (selectedDate)="selectedDueDate($event)"
                        ></snl-date-picker-dropdown>
                    </div>
                </div>
                @if (ccdForm.value?.coaType === 'NEW_RATES_AND_TERMS') {
                <div class="FieldArea CheckBxClause">
                    <snl-checkbox 
                        [isDisable]="ccdRes?.isNewClause"
                        [checked]="isNewClauseChecked"
                        [intermediate]="false"
                        [value]="'withNewClause'"
                        (onClick)="withNewClauseClick($event)"
                        >
                        <label class="section-title">With New Bunker Clause</label>
                        
                    </snl-checkbox>
                </div>
                }
                <div class="FieldArea" [ngClass]="showError('customer') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Counterparty *'" formControlName="customer" [value]="ccdForm.value?.customer" [errorMessage]="showError('customer')"></snl-input>
                    </div>
                </div>
                <div class="FieldArea">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-date-picker-dropdown
                        datePickerType="range"
                        title="Contract period *"
                        [min]="minContractDate"
                        [startMonthDate]="setContractStartDate"
                        [endMonthDate]="setContractEndDate"
                        (selectedDate)="selectedContractPeriod($event)"></snl-date-picker-dropdown>
                    </div>
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-dropdown
                            [label]="'Fleet *'"
                            [buttonPrimary]="'Apply'"
                            [buttonSecondary]="'Reset'"
                            [options]="serviceOptions"
                            [multiple]="false"
                            (selectedValues)="applySelectedService($event)"
                            (resetDropdown)="resetService()"
                            [preSelectedItems]="serviceObj"
                        ></snl-dropdown>
                    </div>
                </div>

                <div class="FieldArea" [ngClass]="showError('lob') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-dropdown
                            [label]="'Line of business (LOB) *'"
                            [buttonPrimary]="'Apply'"
                            [buttonSecondary]="'Reset'"
                            [options]="lobOptions"
                            [multiple]="true"
                            (selectedValues)="applySelectedLob($event)"
                            (resetDropdown)="resetLob()"
                            [preSelectedItems]="lobObj"
                        ></snl-dropdown>
                    </div>
                </div>

                <div class="FieldArea" [ngClass]="showError('product') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Product *'" formControlName="product" [value]="ccdForm.value?.product" [errorMessage]="showError('product')"></snl-input>
                    </div>
                </div>
                
                <div class="FieldArea" [ngClass]="showError('estAnnualVolumeMin') ||showError('estAnnualVolumeMax') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Estimated annual volume (min) *'" formControlName="estAnnualVolumeMin" [value]="ccdForm.value?.estAnnualVolumeMin" [errorMessage]="showError('estAnnualVolumeMin')" [suffixText]="'MT'"></snl-input>
                    </div>
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Estimated annual volume (max) *'" formControlName="estAnnualVolumeMax" [value]="ccdForm.value?.estAnnualVolumeMax" [errorMessage]="showError('estAnnualVolumeMax')" [suffixText]="'MT'"></snl-input>
                    </div>
                </div>

                <div class="FieldArea" [ngClass]="showError('estAnnualFreightMin') ||showError('estAnnualFreightMax') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Estimated annual freight (min) *'" 
                        [leadingIcon]="'fa-solid fa-dollar-sign'" formControlName="estAnnualFreightMin" [value]="ccdForm.value?.estAnnualFreightMin" [errorMessage]="showError('estAnnualFreightMin')"></snl-input>
                    </div>
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Estimated annual freight (max) *'" 
                        [leadingIcon]="'fa-solid fa-dollar-sign'" formControlName="estAnnualFreightMax" [value]="ccdForm.value?.estAnnualFreightMax" [errorMessage]="showError('estAnnualFreightMax')"></snl-input>
                    </div>
                </div>

                <div class="FieldArea" [ngClass]="showError('chartererPartyForm') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-dropdown
                        [label]="'Charter party form *'"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="chartererPartyFormOptions"
                        [multiple]="false"
                        (selectedValues)="applySelectedChartererPartyForm($event)"
                        (resetDropdown)="resetChartererPartyForm()"
                        [preSelectedItems]="chartererPartyFormObj"
                        ></snl-dropdown>
                    </div>
                </div>
                
                <div class="FieldArea" [ngClass]="showError('law') || showError('otherLaw') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-dropdown
                        [label]="'Law *'"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="lawOptions"
                        [multiple]="false"
                        (selectedValues)="applySelectedLaw($event)"
                        (resetDropdown)="resetLaw()"
                        [preSelectedItems]="lawObj"
                    ></snl-dropdown>
                    </div>
                    @if(ccdForm.get('law')?.value === 'Other'){
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input [label]="'Other law *'" formControlName="otherLaw" [value]="ccdForm.value?.otherLaw" [errorMessage]="showError('otherLaw')"></snl-input>
                    </div>
                    }
                </div>

                <div class="FieldArea" [ngClass]="showError('manager') ? 'ErrorSpaceBottom' : ''">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}"> 
                        <snl-dropdown
                        [label]="'Manager *'"
                        [buttonPrimary]="'Apply'"
                        [buttonSecondary]="'Reset'"
                        [options]="managerOptions"
                        [multiple]="false"
                        (selectedValues)="applySelectedManager($event)"
                        (resetDropdown)="resetManager()"
                        [preSelectedItems]="managerObj"
                        ></snl-dropdown>
                    </div>
                </div>
            </div>
            <div class="InfoCardLft ReferenceCdd">
                <h2 class="sub-heading-s1">Reference previous CCD</h2>
                <div class="FieldArea">
                    <div class="InnerCol" [ngClass]="{'Stolt-Skeleton':fieldsLoading}">
                        <snl-input
                            [label]="'CCD# Customer name (optional)'"
                            [helperText]="'Type CCD#/ copy & paste/ Ctrl C + Ctrl V'" 
                            [type]="'text'"
                            formControlName="ccdRefField"
                            [value]="ccdForm.value?.ccdRefField"
                            [errorMessage]="showError('ccdRefField')"
                            (onInputValueChange)="ccdRefChange()"
                        ></snl-input>
                        @if (!isCcdRefCheckClicked) {
                        <snl-button [type]="'secondary'" [size]="'large'" (buttonClick)="checkCcdRef()" [disabled]="isCcdRefDisabled">Check</snl-button>
                        }
                        @else {
                        <div class="Stolt-Skeleton"></div>
                        }
                    </div>  
                </div>
                <!-- Validated Reference CCD  -->
                @if (isCcdRefValidated != undefined) {
                <div class="ValidatedMsg body-b1">
                    @if (isCcdRefValidated) {
                    <div class="ValidateTxt SuccessMsg">
                        <svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>
                        <span>CCD# <strong>{{ccdForm.value?.ccdRefField}}</strong> validated</span>
                    </div>
                    }
                    @else {
                    <div class="ValidateTxt ErrorMsg">
                        <svg-icon class="df-c" src="assets/icons/error_disabled.svg"></svg-icon>
                        <span>Invalid CCD#, please try again/ copy and paste the CCD#</span>
                    </div>
                    }
                </div>
                }
            </div>
        </form>
    </div>
    <!-- Right Card CCD Document  -->
    <div class="InfoCardLft InfoCardRgt DocumentCol">
        <h2 class="sub-heading-s1">Document(s)</h2>
        <div class="DragDropFile">
            <p class="body-b">Upload your documents here</p> 
            <app-upload-files [uploadScreen]="'edit-ccd'" [ccdNo]="ccdNo" [department]="'OWNER'" [documents]="ownerAddedDocuments" [documentLoading]="fetchCcdDataLoading"></app-upload-files>
        </div>
    </div>
</section>
<!-- Sticky Footer Bottom Action Panel -->
<footer class="FixedFooterBar">
    <snl-action-panel>
        <div class="gap16 df-c" left-side>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton':nextLoading}" [size]="'large'" (buttonClick)="onSubmit()" [disabled]="!ccdForm.valid">Save changes</snl-button>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton':saveExitLoading}" [size]="'large'" [type]="'secondary'" (buttonClick)="cancelCcdPopup()">Cancel</snl-button>
        </div>
    </snl-action-panel>
</footer>
@if(openCancelEditCcdPopup) {
    <app-cancel-edit-ccd-popup [open]="openCancelEditCcdPopup" (onButtonClick)="cancelCcd($event)"></app-cancel-edit-ccd-popup>
}
@if(openChangeCoaTypePopup) {
    <app-change-coa-type-popup [open]="openChangeCoaTypePopup" [coaType]="ccdForm.value?.coaType" (onButtonClick)="changeCoaType($event)"></app-change-coa-type-popup>
}