export const COA = {
    "active" : "ACTIVE",
    "upcoming" : "UPCOMING",
    "completed" : "COMPLETED",
    "cancelled" : "CANCELLED",
    "draft" : "DRAFT"
};
export const ACTIVE = [
    {
        "field": "ccdNo",
        "header": "CCD #"
    },
    {
        "field": "customer",
        "header": "Customer name"
    },
    {
        "field": "lob",
        "header": "LOB"
    },
    {
        "field": "owner",
        "header": "Owner"
    },
    {
        "field": "coaType",
        "header": "Type of COA"
    },
    {
        "field": "submittedAt",
        "header": "Start date"
    },
    {
        "field": "dueBy",
        "header": "Due date"
    },
    {
        "field": "",
        "header": ""
    },
    {
        "field": "",
        "header": ""
    }
];
export const UPCOMING = [
    {
        "field": "ccdNo",
        "header": "CCD #"
    },
    {
        "field": "customer",
        "header": "Customer name"
    },
    {
        "field": "lob",
        "header": "LOB"
    },
    {
        "field": "owner",
        "header": "Owner"
    },
    {
        "field": "coaType",
        "header": "Type of COA"
    },
    {
        "field": "coaStartDate",
        "header": "COA start date"
    },
    {
        "field": "coaEndDate",
        "header": "COA due date"
    },
    {
        "field": "pursueCcd",
        "header": "Pursue CCD"
    },
    {
        "field": "",
        "header": ""
    }
];
export const COMPLETED = [
    {
        "field": "ccdNo",
        "header": "CCD #"
    },
    {
        "field": "customer",
        "header": "Customer name"
    },
    {
        "field": "lob",
        "header": "LOB"
    },
    {
        "field": "owner",
        "header": "Owner"
    },
    {
        "field": "coaType",
        "header": "Type of COA"
    },
    {
        "field": "submittedAt",
        "header": "Start date"
    },
    {
        "field": "dueBy",
        "header": "Due date"
    },
    {
        "field": "datePublished",
        "header": "Date published"
    }
];
export const CANCELLED = [
    {
        "field": "ccdNo",
        "header": "CCD #"
    },
    {
        "field": "customer",
        "header": "Customer name"
    },
    {
        "field": "lob",
        "header": "LOB"
    },
    {
        "field": "owner",
        "header": "Owner"
    },
    {
        "field": "coaType",
        "header": "Type of COA"
    },
    {
        "field": "submittedAt",
        "header": "Start date"
    },
    {
        "field": "dueBy",
        "header": "Due date"
    },
    {
        "field": "cancellationNotes",
        "header": "Cancellation notes"
    },
    {
        "field": "",
        "header": ""
    }
];
export const DRAFT = [
    {
        "field": "ccdNo",
        "header": "CCD #"
    },
    {
        "field": "customer",
        "header": "Customer name"
    },
    {
        "field": "lob",
        "header": "LOB"
    },
    {
        "field": "owner",
        "header": "Owner"
    },
    {
        "field": "coaType",
        "header": "Type of COA"
    },
    {
        "field": "createdAt",
        "header": "Start date"
    },
    {
        "field": "dueBy",
        "header": "Due date"
    },
    {
        "field": "",
        "header": ""
    },
    {
        "field": "",
        "header": ""
    }
];

export const DEPARTMENTS = [
    {
        fieldName: 'LEGAL',
        header: 'Legal department',
        visibleIn : ['NEW_COA', 'NEW_RATES_AND_TERMS', 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE']
    },
    {
        fieldName: 'ACCOUNTING',
        header: 'Accounting department',
        visibleIn : ['NEW_COA']
    },
    {
        fieldName: 'OPERATIONS_MANAGER',
        header: 'Operations manager',
        visibleIn : ['NEW_COA','NEW_RATES_AND_TERMS', 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE']
    },
    {
        fieldName: 'TANKER',
        header: 'Tanker controller',
        visibleIn : ['NEW_COA', 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE']
    },
    {
        fieldName: 'DEMURRAGE',
        header: 'Demurrage',
        visibleIn : ['NEW_COA', 'NEW_RATES_AND_TERMS', 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE', 'FINANCIAL_NEGOTIATIONS']
    },
    {
        fieldName: 'GROUP_RISK',
        header: 'Group risk',
        visibleIn : ['NEW_COA', 'NEW_RATES_AND_TERMS', 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE', 'FINANCIAL_NEGOTIATIONS']
    },
    {
        fieldName: 'MANAGEMENT',
        header: 'Management approval',
        visibleIn : ['NEW_COA', 'NEW_RATES_AND_TERMS', 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE', 'FINANCIAL_NEGOTIATIONS']
    }
];
export const DEPARTMENTORDER = [
    "LEGAL", 
    "ACCOUNTING", 
    "OPERATIONS_MANAGER", 
    "TANKER", 
    "DEMURRAGE", 
    "GROUP_RISK", 
    "MANAGEMENT", 
    "OWNER"
  ];
export const USERTABLEHEADINGS = [
    {
        "field": "name",
        "header": "Name"
    },
    {
        "field": "email",
        "header": "Email"
    },
    {
        "field": "role",
        "header": "Role"
    },
    {
        "field": "status",
        "header": "Status"
    }
];
