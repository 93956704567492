export const Toasts = {
    Types: {
        Error: 'Error',
        Success: 'Success',
        Info: 'Info',
        Warning: 'Warning',
    },
    Actions: {
        Snooze: {
            Title: 'Notification Snoozed',
            Error: `Alert couldn't be snoozed. Please try again.`,
        },
        Publish: {
            Title: 'CCD published',
            Success: 'has been published successfully.',
            Error: `This CCD couldn't be published. Please try again.`,
        },
        Cancel: {
            Title: 'CCD cancelled',
            Success: 'has been cancelled successfully.',
            Error: `This CCD couldn't be cancelled. Please try again.`,
        },
        Pursue: {
            Title: 'CCD pursued',
            WarningTitle: 'CCD Locked',
            Success: 'has been pursued successfully.',
            Error: `This CCD couldn't be pursued. Please try again.`,
        },
        Initiate: {
            Title: 'CCD initiated',
            WarningTitle: 'CCD Locked',
            Success: 'has been initiated successfully.',
            Error: `This CCD couldn't be initiated. Please try again.`,
        },
        Reactivate: {
            Title: 'CCD reactivated',
            WarningTitle: 'CCD Locked',
            Success: 'has been reactivated successfully.',
            Error: `This CCD couldn't be reactivated. Please try again.`,
        },
        Delete: {
            Title: 'Draft CCD deleted',
            Success: 'Draft CCD# has been deleted successfully.',
            Error: `This draft CCD couldn't be deleted. Please try again.`,
        },
        Submit: {
            Title: 'CCD submitted',
            Success: 'has been sent to the selected departments successfully.',
            Error: `This draft CCD couldn't be submitted. Please try again.`,
        },
        AutoSave: {
            Title: 'Draft CCD saved',
            Success: 'Your progress has been saved as a draft.',
            Error: `Your progress couldn't be saved. Please try again.`
        },
        SaveAsDraft: {
            Title: 'Draft CCD saved',
            Success: 'CCD# is saved as draft',
            Error: `This CCD couldn't be saved as draft. Please try again.`
        },
        DeleteDocument: {
            Title: 'Document deleted',
            Success: 'Document deleted successfully.',
            Error: `This document couldn't be deleted. Please try again.`,
        },
        DownloadDocument: {
            Title: 'Document downloaded',
            Success: 'Document downloaded successfully.',
            Error: `This document couldn't be downloaded. Please try again.`,
        },
        MarkComplete: {
            Title: 'Mark as complete',
            WarningTitle: 'Mark as complete Locked',
            Success: 'has been changed successfully.',
            Error: `This maerk as complete status couldn't be changed. Please try again.`,
        }
    }
}

export const customizedMessages = (type: string, ccdNo: string, customer = '') => {
    let message = '';

    switch (type) {
        case 'publish':
            message = `CCD# ${ccdNo} ${Toasts.Actions.Publish.Success}`;
            break;
        case 'cancel':
            message = `CCD# ${ccdNo} ${Toasts.Actions.Cancel.Success}`;
            break;
        case 'initiate':
            message = `CCD# ${ccdNo} ${Toasts.Actions.Initiate.Success}`;
            break;
        case 'submit':
            message = `CCD# ${ccdNo} for ${customer.toUpperCase()} ${Toasts.Actions.Submit.Success}`;
            break;
        default:
            break;
    }
    return `${message}`;
}