import { Injectable } from "@angular/core";
import * as CryptoTS from 'crypto-ts';

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  API_SIGNATURE = 'WEsJm.E72yjWO1XXNP)Id_3Y8';

  encryptionAES(msg: any) {
    const ciphertext = CryptoTS.AES.encrypt(msg, this.API_SIGNATURE);
    return ciphertext.toString();
  }

  decryptionAES(msg: any) {
    const bytes = CryptoTS.AES.decrypt(msg, this.API_SIGNATURE);
    const plaintext = bytes.toString(CryptoTS.enc.Utf8);
    return plaintext;
  }
}