import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { EncryptionService } from '../encryption.service';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  let authReq: any = req;
  const authService = inject(AuthService);
  const encryptionService = inject(EncryptionService);

  if (req.url.startsWith(environment.BASE_URL) && Object.keys(authService.UserObj).length > 0) {
    const match = authService.UserObj.displayName.match(/\(([^)]+)\)/);
    const userInitials = match ? match[1] : authService.UserObj.displayName;
    const userInfo:any = {
      userId: authService.UserObj.id,
      userEmail: authService.UserObj.userPrincipalName,
      userName: userInitials,
      name: authService.UserObj.displayName,
    };
    console.log('user',userInfo)
    if(authService?.backendUserObj?.role) {
      userInfo['userRole'] = authService.backendUserObj.role
    }
    const encryptedUserInfo = encryptionService.encryptionAES(JSON.stringify(userInfo));

    // Clone the request and add the authorization header
    authReq = req.clone({
      setHeaders: {
        userAuth: encryptedUserInfo,
        authorization: 'Bearer ' + authService.getAccessToken()
      }
    });
  }

  return next(authReq).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          // Specific handling for unauthorized errors         
          console.error('Unauthorized request:', err);
          // You might trigger a re-authentication flow or redirect the user here
        } else {
          // Handle other HTTP error codes
          console.error('HTTP error:', err);
        }
      } else {
        // Handle non-HTTP errors
        console.error('An error occurred:', err);
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    })
  );
};
