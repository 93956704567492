<main class="main">
    <!-- Sidebar Section -->
    <aside class="sidebar">
      <app-left-menu
        [navigationUrl]="navigationUrl"
        [displayName]="'Dashboard'"
      ></app-left-menu>
    </aside>

    <!-- Main Section Wrapper -->
    <section class="container DataBodyContainer">
      <div [ngClass]="ownerName ? 'header-wrapper' : ''">
        <snl-page-title [list]="breadCrumbList" (selectedItem)="selectedBreadcrumb($event)">
          <div title-content class="page-title-cstm">
            <snl-icon class="df-c" *ngIf="backButtonNavigateTo"
              [icon]="'fa-solid fa-chevron-left'" 
              (click)="navigateBack(backButtonNavigateTo)"
            ></snl-icon>
            <span class="header-h1">{{header_title}}</span>
          </div>
        </snl-page-title>
        @if (ownerName) {
        <div class="owner-name body-b1">CCD owner: <strong> {{ownerName}} </strong></div>
        }
      </div>

      <!-- Main Page Container -->
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </section>
</main>
  