import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { CommonModule } from '@angular/common';

import { ChangeEvent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {
	ClassicEditor,
	AccessibilityHelp,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	Code,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	Highlight,
	Italic,
	Link,
	List,
	Paragraph,
	SelectAll,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Underline,
	Undo,
	type EditorConfig
} from 'ckeditor5';
import { ProfileImageModule } from 'snl-complib';
import { SvgIconComponent } from 'angular-svg-icon';
import { AuthService } from 'src/app/services/auth.service';
import { CcdService } from 'src/app/services/ccd.service';

@Component({
  selector: 'app-notes',
  standalone: true,
  imports: [CommonModule, CKEditorModule, SvgIconComponent, ProfileImageModule],
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NotesComponent {
	navigationUrl: any = '';
	lastUpdateDate: any;
	public collapsedToggle:boolean = true;
	@Input() from:string = '';

	constructor(
		private router: Router,
		public authService: AuthService,
		public ccdService: CcdService,
		public navigationService: NavigationService,
		private changeDetector: ChangeDetectorRef,
		private sanitizer: DomSanitizer
	) {
		this.router.events.subscribe((event) => {
		this.navigationService.setPageTitle('Contract Control Document - Dashboard');
		if (event instanceof NavigationEnd) {
			this.parseRoute(this.router.routerState.snapshot.root);
			let routeUrl = event.urlAfterRedirects.split('/');
			this.navigationUrl = routeUrl[1];
		}
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
			return;
			}
			window.scrollTo(0, 0);
		});
		});

		let startTime = new Date();
		this.lastUpdateDate = new Date(
		startTime.getTime() + startTime.getTimezoneOffset() * 60000
		);
	}

	private parseRoute(node: ActivatedRouteSnapshot) {
		if (node.firstChild) {
		this.parseRoute(node.firstChild);
		}
	}
	
	@Input() selectedTab:string = '';
	@Input() accessOfNotesEditor:boolean = false;
	@Input() ccdNo:string | null = null;
	public notesLegendStatus:string = 'no_notes' //no_notes, notes_added
	public currentUser: any = [];
	public fetchCcdNotesLoading: boolean = false;
	public createCcdNotesLoading: boolean = false;
    public ccdNotes : any = [];
	public data : any;
	public editor : any;
	public isLayoutReady = false;
	public Editor = ClassicEditor;
	public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.

	ngOnInit(): void {
		this.getNotesLegendStatus();
		this.fetchCcdNotes();
		this.authService.getBackendUserData().subscribe((data: any) => {
		  if (data) {
			this.currentUser = data;
		  }
		});
	}
	public ngAfterViewInit(): void {
		this.config = {
			toolbar: {
				items: [
					'bold',
					'italic',
					'underline',
					'strikethrough',
					// 'undo',
					// 'redo',
					'|',
					'bulletedList',
					'numberedList',
					'|',
					'highlight',
					// 'fontSize',
					// 'fontFamily',
					'fontColor',
					// 'heading',
					// '|',
					// 'blockQuote',
					// 'link',
					// 'subscript',
					// 'superscript',
					// 'code',
					// 'specialCharacters',
					
				],
				shouldNotGroupWhenFull: false
			},
			plugins: [
				AccessibilityHelp,
				AutoLink,
				Autosave,
				BlockQuote,
				Bold,
				Code,
				Essentials,
				FontBackgroundColor,
				FontColor,
				FontFamily,
				FontSize,
				Heading,
				Highlight,
				Italic,
				Link,
				List,
				Paragraph,
				SelectAll,
				SpecialCharacters,
				SpecialCharactersArrows,
				SpecialCharactersCurrency,
				SpecialCharactersEssentials,
				SpecialCharactersLatin,
				SpecialCharactersMathematical,
				SpecialCharactersText,
				Strikethrough,
				Subscript,
				Superscript,
				Underline,
				Undo
			],
			fontFamily: {
				supportAllValues: true
			},
			fontSize: {
				options: [10, 12, 14, 'default', 18, 20, 22],
				supportAllValues: true
			},
			// heading: {
			// 	options: [
			// 		{
			// 			model: 'paragraph',
			// 			title: 'Paragraph',
			// 			class: 'ck-heading_paragraph'
			// 		},
			// 		{
			// 			model: 'heading1',
			// 			view: 'h1',
			// 			title: 'Heading 1',
			// 			class: 'ck-heading_heading1'
			// 		},
			// 		{
			// 			model: 'heading2',
			// 			view: 'h2',
			// 			title: 'Heading 2',
			// 			class: 'ck-heading_heading2'
			// 		},
			// 		{
			// 			model: 'heading3',
			// 			view: 'h3',
			// 			title: 'Heading 3',
			// 			class: 'ck-heading_heading3'
			// 		},
			// 		{
			// 			model: 'heading4',
			// 			view: 'h4',
			// 			title: 'Heading 4',
			// 			class: 'ck-heading_heading4'
			// 		},
			// 		{
			// 			model: 'heading5',
			// 			view: 'h5',
			// 			title: 'Heading 5',
			// 			class: 'ck-heading_heading5'
			// 		},
			// 		{
			// 			model: 'heading6',
			// 			view: 'h6',
			// 			title: 'Heading 6',
			// 			class: 'ck-heading_heading6'
			// 		}
			// 	]
			// },
			initialData:
				'',
			// link: {
			// 	addTargetToExternalLinks: true,
			// 	defaultProtocol: 'https://',
			// 	decorators: {
			// 		toggleDownloadable: {
			// 			mode: 'manual',
			// 			label: 'Downloadable',
			// 			attributes: {
			// 				download: 'file'
			// 			}
			// 		}
			// 	}
			// },
			placeholder: 'Enter notes ...'
		};

		this.isLayoutReady = true;
		this.changeDetector.detectChanges();
	}

	scrollToBottom(): void {
		setTimeout(() => {
			const chatClass = this.from === 'review' ? 'reviewChatMsg' : 'notesPopupChatMsg';
			const chatContainer = document.querySelector('.' + chatClass);
			console.log('chatContainer',chatContainer?.scrollHeight)
			if (chatContainer) {
				chatContainer.scrollTop = chatContainer.scrollHeight;
			}
		}, 10);
	}


	getNotesLegendStatus(){
	  this.ccdService.getDepartmentSpecificNotesLegend().subscribe((event)=>{
		  this.notesLegendStatus = event?.notesLegendStatus;
	  })
	}

	fetchUserInitials(name: string) {
		if (!name) {
			return '';
		}
		if (!name.includes('(') || !name.includes(')')) {
			return name.trim();
		}
		return name.substring(name.indexOf('(') + 1, name.indexOf(')')).trim();
	}
	fetchUserName(name: string) {
		if (!name) {
			return '';
		}
		if (!name.includes('(')) {
			return name.trim();
		}
		return name.substring(0, name.indexOf('(')).trim();
	}
	getFormattedDateTime(input:Date){
		const date = new Date(input);
		const day = String(date.getDate()).padStart(2, '0'); // Day of the month
		const month = date.toLocaleString('default', { month: 'short' }); // Short month name
		const year = date.getFullYear(); // Year
		const hours = date.getHours(); // Hours in 24-hour format
		const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutes

		const formattedDate = `${day} ${month} ${year} - ${hours}:${minutes}`;
		return formattedDate;
	}
	saferHtml(notes:string){
		return this.sanitizer.bypassSecurityTrustHtml(notes);
	}
	
	public checkChanges({ editor }: ChangeEvent){
		this.editor = editor;
		this.data = editor.getData();
		// console.log( this.data );
	}

	fetchCcdNotes() {
	  this.fetchCcdNotesLoading = true;
	  this.ccdService.fetchCcdNotes({ ccdNo: this.ccdNo, department: this.selectedTab }).subscribe({
		next: ({notes}: any) => {
		  this.ccdNotes = notes;
		  this.scrollToBottom();
		  this.fetchCcdNotesLoading = false;
		},
		error: (err: any) => {
		  this.fetchCcdNotesLoading = false;
		}
	  });
	}

	public addNotes(){
		const noteObj = {
			"department": this.selectedTab,
			"notes": this.data,
			"userName": this.currentUser?.name,
			"createdAt": new Date()
		}
		this.ccdNotes.push(noteObj);
		this.createNotes(this.data);
		this.data = '';
		this.editor.setData('');
		this.scrollToBottom();
	}

	createNotes(note:string) {
	  const	notesObj = {
		ccdNo: this.ccdNo,
		department: this.selectedTab,
		notes: note
	  }
	  this.createCcdNotesLoading = true;
	  this.ccdService.createNotes({ notesObj: notesObj }).subscribe({
		next: ({notes}: any) => {
			this.updateLegendStatus();
		  this.createCcdNotesLoading = false;
		},
		error: (err: any) => {
		  this.createCcdNotesLoading = false;
		}
	  });
	}

	updateLegendStatus(){
	  if(this.notesLegendStatus === 'no_notes'){
		this.notesLegendStatus = 'notes_added';
		const reqObj = {
		  ccdNo: this.ccdNo,
		  action: "NOTES",
		  department: this.selectedTab,
		};
		this.ccdService.updateLegend(reqObj).subscribe({
		  next: () => {
			this.ccdService.setUpdatedNotesLegend({ department: this.selectedTab });
			console.log('Notes legend updated successfully');
		  },
		  error: (err: any) => {
			console.error('Error updating Notes legend:', err);
		  }
		});
	  }
	}

	toggleClick(){
		this.collapsedToggle = !this.collapsedToggle;
		
	}
}

