import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //Set and get MSAL Logged in User Details in variable and BehaviorSubject
  public UserObj: any = {};
  private UserSubject = new BehaviorSubject({});
  accessToken: BehaviorSubject<any> = new BehaviorSubject(null);
  public backendUserObj: any;
  private backendUserSubject = new BehaviorSubject<any>(null);
  setUserData(value: any) {
    this.UserObj = value;
    this.UserSubject.next(value);
  }

  getUserData() {
    return this.UserSubject;
  }

  setBackendUserData(value: any) {
    this.backendUserObj = value;
    this.backendUserSubject.next(value);
  }

  getBackendUserData() {
    return this.backendUserSubject;
  }

  setAccessToken(value: any) {
    this.accessToken.next(value);
  }

  getAccessToken() {
    return this.accessToken.value;
  }
}
