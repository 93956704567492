import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { NavigationModule } from 'snl-complib';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth.service';
import { CcdService } from 'src/app/services/ccd.service';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-left-menu',
  standalone: true,
  imports: [NavigationModule],
  templateUrl: './left-menu.component.html',
})
export class LeftMenuComponent {
  @Input() navigationUrl: any;
  @Input() displayName: any;
  menuOptions: any = '';
  signOutOption: any = '';
  isCollapsed: any;
  splitted: any;
  userOption: any = {
    displayName: '',
    designation: '',
  };
  environmentType: any = '';
  bottomOption: any;
  role: any;
  eventValue: any;
  heading: any;
  open: boolean = false;
  deleteMsg: any;
  isSubjectValue: any;
  constructor(
    private router: Router,
    private navService: NavigationService,
    private renderer: Renderer2,
    private authService: AuthService,
    private msalService: MsalService,
    private ccdService: CcdService,
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit(): void {
    this.environmentType = environment.type;
    this.loadMenuOption(this.navigationUrl);
    this.getUserData();
  }

  getUserData() {
    this.authService.getUserData().subscribe((profile: any) => {
      if (profile?.displayName) {
        let displayName = profile?.displayName.split(' ');
        const userInitials = displayName[displayName.length - 1].replace(/[(|)]/g, '');
        const name: any = displayName.splice(0, displayName.length - 1).join(' ');
        this.userOption = {
          displayName: name,
          designation: profile?.jobTitle,
          initials: userInitials
        };
      }
    })
  }

  async loadMenuOption(navigationUrl: string) {
    this.menuOptions = [
      {
        iconName: 'fa-solid fa-border-all',
        displayName: 'Dashboard',
        selected: navigationUrl == '' ? true : this.menuselected(navigationUrl, '') || this.menuselected(navigationUrl, 'dashboard'),
        path: 'dashboard',
      },
    ];

    this.signOutOption = {
      iconName: 'fa-solid fa-sign-out',
      displayName: 'Sign Out',
      selected: false,
      path: 'logout',
    };
  }

  public menuselected(select: string, menu: string) {
    if (select == menu) {
      return true;
    }
    return false;
  }

  public userLogout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUri
    });

    localStorage.clear();
    this.updateLogs();
  }

  updateLogs() {
    this.ccdService.updateAuditLogs({ type: 'logout' }).subscribe((response) => {
      console.log('Logout Logs Updated:', response);
      this.storageService.putDataInStorage('loggedIn', 'false');
    });
  }

  public selectedOption(event: any) {

    if (event.option && event.option.path == 'logout') {
      this.userLogout();
    } else if (event.option.path && event.option.path != 'logout') {
      this.loadMenuOption(event.option.path);
      this.router.navigate([event.option.path]);
    }
  }

  public navigationCollapse(event: any) {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsed
      ? this.renderer.addClass(this.document.body, 'leftbar-collapsed')
      : this.renderer.removeClass(this.document.body, 'leftbar-collapsed');
    this.navService.isNavigationCollapseSet(this.isCollapsed);
  }
}
