<snl-modal class="CommnModalChanges CoomonModel400" (modalClose)="closeModalPopUp()" [settings]="modalSettings" [type]="'small'" [open]="open"> 
    <div slot="heading">Changing type of COA</div> 
    <div slot="modalContent">
       <p class="body-b1">
        Are you sure you want to change the type of COA to {{typeOfCoa[coaType]}}?
       </p>
    </div> 
    <div slot="modalFooter">
      <snl-button class="BtnSkeleton" size="large" (click)="changeCoaType({isChangeCoaType: true})">Yes</snl-button>
      <snl-button size="large" type="secondary" (click)="closeModalPopUp()">Cancel</snl-button>
    </div>
</snl-modal>
