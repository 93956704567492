<section class="MainCard"> 
    <!-- Tabs  -->
    <div class="TabsHolder">
        <snl-page-tab [isSelected]="true"><svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>Owner</snl-page-tab>
        <snl-page-tab><svg-icon class="df-c" src="assets/icons/action_taken.svg"></svg-icon>Legal</snl-page-tab>
        <snl-page-tab><svg-icon class="df-c" src="assets/icons/no_action_taken.svg"></svg-icon>Accounting</snl-page-tab>
        <snl-page-tab><svg-icon class="df-c" src="assets/icons/no_action_taken.svg"></svg-icon>Operations manager</snl-page-tab>
        <snl-page-tab><svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>Tanker controller</snl-page-tab>
        <snl-page-tab><svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>Demurrage</snl-page-tab>
        <snl-page-tab><svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>Group risk</snl-page-tab> 
    </div>

    <div class="CardHolder">
        <!-- Left Card CCD Preview Details  -->
        <div class="InfoCardLftHolder">
            <div class="InfoCardLft">
                <!-- Preview Details  -->
                <h2 class="sub-heading-s1 EditBtn">
                    Preview - CCD details
                    <snl-button [type]="'tertiary'" [size]="'small'" [icon]="'fa fa-pencil'">
                        Edit
                    </snl-button>
                </h2> 
                <ul class="OptionsList">
                    <li>
                        <span class="body-b LeftCell">Type of COA</span>
                        <span class="section-title">New COA</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Due date</span>
                        <span class="section-title">28 Jun 2024</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Counterparty</span>
                        <span class="section-title">TRICON ENERGY LTD.</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Contract period</span>
                        <span class="section-title">01 May 2024 - 31 Dec 2024</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Line of business</span>
                        <span class="section-title">AAG, AG-ECSA, AGE, GIP, GIR-C, GIR-M</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Service</span>
                        <span class="section-title">TAS Loading USG discharge Rotterdam or Antwerp</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Product</span>
                        <span class="section-title">STYRENE</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Estimated annual volume</span>
                        <span class="section-title">21,000 MT - 35,000 MT</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Estimated annual freight</span>
                        <span class="section-title">28 Jun 2024</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Charter party form</span>
                        <span class="section-title">ASBTANKVOY</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Law</span>
                        <span class="section-title">USA</span>
                    </li>
                    <li>
                        <span class="body-b LeftCell">Manager</span>
                        <span class="section-title ApproveBtn">
                            Marlene Nicolaou - Boyer (MYE)
                            <snl-button (click)="open = true" [type]="'primary'" [size]="'small'">
                                Approve
                              </snl-button>
                        </span>
                    </li>
                </ul> 

                <!-- Document(s) -->
                    <h2 class="sub-heading-s1">Document(s)</h2> 
                    <ul class="OptionsList DocumentList body-b1">
                    <li>Stolt Tankers-Tricon-StyreneMonomer-USGtoARA-COA-Sub-Recap1.docx<svg-icon src="assets/icons/download.svg"></svg-icon></li>
                    <li>Stolt Tankers-Tricon-StyreneMonomer-USGtoARA-COA-Sub-Recap1.docx<svg-icon src="assets/icons/download.svg"></svg-icon></li>
                    <li>Stolt Tankers-Tricon-StyreneMonomer-USGtoARA-COA-Sub-Recap1.docx<svg-icon src="assets/icons/download.svg"></svg-icon></li>
                </ul> 

                <!-- CCD reference -->
                <h2 class="sub-heading-s1">CCD reference</h2> 
                <!-- Validated Reference CCD  -->
                <div class="ValidatedMsg body-b1">
                    <svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>
                    <span>CCD# <strong>0025/23 Tricon Energy Ltd.</strong></span> 
                </div> 
            </div> 
        </div>
        
        <!-- Right Card CCD Departments’ notes  -->
        <div class="InfoCardLft InfoCardRgt">
            <h2 class="sub-heading-s1">Departments’ notes</h2> 
            <ul>
                <li>
                    <span class="body-b FieldTitle">Legal department</span>
                    <span class="section-title">Please let us know if you see anything.  I think attached has been well cleaned up,  but in case I missed anything</span>
                </li>
                <li>
                    <span class="body-b FieldTitle">Accounting department</span>
                    <span class="section-title">Please let us know if you have any outstanding issues / Credit worthiness</span>
                </li>
                <li>
                    <span class="body-b FieldTitle">Operations manager</span>
                    <span class="section-title">Please let us know if you see any operational issues</span>
                </li>
                <li>
                    <span class="body-b FieldTitle">Tanker controller</span>
                    <span class="section-title">Please let us know if you see any issues</span>
                </li>
                <li>
                    <span class="body-b FieldTitle">Demurrage</span>
                    <span class="section-title">Please let us know of any major outstanding demurrage issues and it the attached modified terms are in need of further modification</span>
                </li>
                <li>
                    <span class="body-b FieldTitle">Group risk</span>
                    <span class="section-title">Please let us know if you see anything special about handling styrene</span>
                </li>
                <li>
                    <span class="body-b FieldTitle">Management approval</span>
                    <span class="section-title">Please review and approve</span>
                </li>
            </ul>  
        </div>
    </div>
</section>

<!-- Sticky Footer Bottom Action Panel -->
<footer class="FixedFooterBar">
    <snl-action-panel>
        <div class="df-c SwitchCstm" right-side>
            <span class="section-title">Mark as complete</span>
            <snl-switch class="LargeSwitch"  
            [activeText]="'YES'"
            [inActiveText]="'NO'" 
          ></snl-switch>
        </div> 
    </snl-action-panel>
</footer>



<!-- Approve CCD Model -->
<snl-modal class="CommnModalChanges ApproveCCDMdl" (modalClose)="open = false" [type]="'small'" [open]="open"> 
    <div slot="heading">Approve CCD</div> 
    <div slot="headingDescription">
        Please enter approval note to approve CCD
    </div>
    <div slot="modalContent">
        <form>
            <ul>
                <li class="ApproverCheckBx">
                    <p class="body-b">Approver</p>
                    <p class="section-title">Marlene Nicolaou Boyer (MYE)</p>
                    <snl-checkbox
                     [isDisable]="false"
                     [checked]="false"
                     [intermediate]="false"
                     [id]="'checkbox-btn-sample'"
                     [name]="'Checkbox'"
                     [value]="'sample'"
                     >
                     Approving on behalf of
                     </snl-checkbox>

                     <div class="attachedSerach">
                        <snl-input
                            [value]="''"
                            [label]="'Name/ Initial'"
                            [isTrailingIconDisplayed]="true"
                            [type]="'search-input'"
                            formControlName="ShipName"
                        ></snl-input>

                        <div class="DragDropFile">
                            <p class="body-b">Add attachment (optional)</p> 
                            <snl-file-upload
                            [isDisable]="false"
                            [uploadedLabel]="'Uploaded'"
                            [buttonLabel]="'Select file'"
                            [buttonIcon]="'fa-solid fa-upload'"
                            [supportingText]="'Supported file format: XXX. Max file size: XMB'"
                            [maxFileSize]="2"
                            [maxFileSizeUnit]="'MB'"
                            [allowedFileTypes]="['image/jpeg', 'image/png']" 
                            ></snl-file-upload>
                        </div>
                     </div>
                </li>
                <li><snl-date-picker-dropdown datePickerType="date"></snl-date-picker-dropdown></li>
                <li><snl-input [label]="'Approval note'"></snl-input></li>
            </ul>
        </form>
    </div> 
    <div slot="modalFooter">
      <snl-button size="large">Submit</snl-button>
      <snl-button size="large" type="secondary">Cancel</snl-button>
    </div>
</snl-modal>