import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  API_SIGNATURE = 'WEsJm.E72yjWO1XXNP)Id_3Y8';

  putDataInStorage(storageKey: any, storageData: any) {
    let enckey = btoa(storageKey);
    let encrValue = this.encryptionAES(JSON.stringify(storageData));
    localStorage.setItem(enckey, encrValue);
  }

  getDataFromStorage(storageKey: any) {
    let enckey = btoa(storageKey);

    if (localStorage.getItem(enckey)) {
      return JSON.parse(this.decryptionAES(localStorage.getItem(enckey)));
    }
    return false;
  }

  removeDataFromStorage(storageKey: any) {
    let enckey = btoa(storageKey);
    return localStorage.removeItem(enckey);
  }

  encryptionAES(msg: any) {
    const ciphertext = CryptoTS.AES.encrypt(msg, this.API_SIGNATURE);
    return ciphertext.toString();
  }

  decryptionAES(msg: any) {
    const bytes = CryptoTS.AES.decrypt(msg, this.API_SIGNATURE);
    const plaintext = bytes.toString(CryptoTS.enc.Utf8);
    return plaintext;
  }
}
