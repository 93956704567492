<div class="ckeditorWrapper">
  <div class="ChatMsg __commentUserMn scroll" [ngClass]="from === 'review' ? 'reviewChatMsg' : 'notesPopupChatMsg'">
  @if (fetchCcdNotesLoading) {
    <div class="chat-msg-inner Chat-Skeleton" *ngFor="let number of [0,1,2,3]">
      <div class="chat-item Stolt-Skeleton Profile-Skeleton"></div>
      <div class="chat-item Msg-Skeleton">
        <span class="Stolt-Skeleton"></span>
        <span class="Stolt-Skeleton"></span>
        <span class="Stolt-Skeleton"></span>
      </div>
    </div>
  }
  @else {
    @for (note of ccdNotes; track $index) {
      <div class="chat-msg-inner"> 
        <div class="chat-item">
            <snl-profile-image [profileInitial]="fetchUserInitials(note?.userName)" [size]="'small'"></snl-profile-image>
        </div>
        <div class="chat-item">
            <p class="body-b2 UserName">{{fetchUserName(note?.userName)}}</p> 
            <span class="caption">{{getFormattedDateTime(note?.createdAt)}}</span>
            <p class="body-b1 commentUser" [innerHTML]="saferHtml(note?.notes)"></p>
        </div>
      </div>
    }
  }
  </div>
  <div *ngIf="accessOfNotesEditor">
    <div class="main-container">
      <div class="editor-container editor-container_classic-editor" [ngClass]="isEditorFocused ? 'focused_container_editor' :''" #editorContainerElement>
        <div class="editor-container__editor" [ngClass]="!collapsedToggle ? 'OptionsHideEdtr' : ''">
          <svg-icon (click)="toggleClick()" class="df-c chevronToggle" src="assets/icons/chevron.svg"></svg-icon>            
          <div #editorElement>
            <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="data" *ngIf="isLayoutReady" (change)="checkChanges($event)" (ready)="onReady($event)"/>
            <button [ngClass]="!data ? 'disableSendButton' : ''" class="SendMsg section-title" type="button" (click)="addNotes()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>