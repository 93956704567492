
import { CommonModule } from '@angular/common'; 
import { Component, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, CheckboxModule, DatePickerModule, DropdownModule, FileUploadModule, InputModule, LinkModule, ModalModule, PageTabModule, PageTitleModule, SwitchModule } from 'snl-complib';

@Component({
  selector: 'app-review-and-approve',
  standalone: true,
  imports: [CommonModule,SvgIconComponent, DropdownModule,DatePickerModule,InputModule,ButtonModule,ActionPanelModule,PageTitleModule,LinkModule,PageTabModule,SwitchModule,ModalModule,CheckboxModule,FileUploadModule
  ],
  templateUrl: './review-and-approve.component.html',
  styleUrls: ['./review-and-approve.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewAndApproveComponent {
  open: boolean = false;
}
