<snl-navigation
[menuOptions]="menuOptions"
[userOption]="userOption"
[signOutOption]="signOutOption"
[bottomOptions]="bottomOption"
[isCollapsed]="isCollapsed"
[environmentType]="environmentType"
(selectedOption) = 'selectedOption($event)'
(navigationCollapse) = 'navigationCollapse($event)'
>
  <div expanded>
    <img alt="No Image"
      src="https://delightful-ground-047d41903.2.azurestaticapps.net/assets/stolt-tankers-logo.svg"
    />
  </div>
  <div collapsed>
    <img alt="No Image"
      src="https://delightful-ground-047d41903.2.azurestaticapps.net/assets/logo_small.svg"
    />
  </div>
</snl-navigation>
