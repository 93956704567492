import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { LeftMenuComponent } from '../left-menu/left-menu.component';
import { CommonModule } from '@angular/common';
import { IconModule, LinkModule, PageTitleModule } from 'snl-complib';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [LeftMenuComponent, PageTitleModule, CommonModule, RouterModule, IconModule, LinkModule],
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  header_title: string = '';
  backButtonNavigateTo: string = '';
  breadCrumbList: any = [];
  navigationUrl: any = '';
  lastUpdateDate: any;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.subscribe((event) => {
      this.navigationService.setPageTitle('ST Contract Control Document - Dashboard');
      if (event instanceof NavigationEnd) {
        let routeUrl = event.urlAfterRedirects.split('/');
        this.navigationUrl = routeUrl[1];
      }

      //scroll to top after every route navigation
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    });
  }

  ngOnInit() {
    this.navigationService.getHeaderData().subscribe((data: any) => {
      this.header_title = data?.headerTitle;
      this.backButtonNavigateTo = data?.backButtonNavigateTo;
      this.breadCrumbList = data?.breadCrumbList;
      this.cdr.detectChanges();
    });
  }

  navigateBack(navigateTo:string) {
    this.router.navigateByUrl(navigateTo);
  }

  selectedBreadcrumb(event:any){
    if(event?.navigateTo){
      this.router.navigateByUrl(event.navigateTo);
    }
  }

}
