import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule, CheckboxModule, DatePickerModule, FileUploadModule, InputModule, ModalModule } from 'snl-complib';
import { UploadFilesComponent } from 'src/app/components/upload-files/upload-files.component';
import { CcdService } from 'src/app/services/ccd.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-approve-popup',
  standalone: true,
  imports: [ModalModule, CheckboxModule, InputModule, FileUploadModule, DatePickerModule, ButtonModule, ReactiveFormsModule, NgClass, UploadFilesComponent],
  templateUrl: './approve-popup.component.html',
  styleUrl: './approve-popup.component.scss'
})
export class ApprovePopupComponent {
  disableSubmitButton:boolean = true;
  modalSettings: any;
  @Input() open: boolean = false;
  @Input() ccdData: any = [];
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();
  public approveCcdForm!: FormGroup;
  public setApprovalDate: Date = new Date();
  public maxApprovalDate: Date = new Date();
  public submitLoading: boolean = false;
  public uploadedDocuments:any = [];
  
  constructor(
    private ccdService: CcdService,
    private validationService: ValidationService
  ) { }

  ngOnInit(){
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
    this.createApproveCcdForm();
    this.setApprovalDateFunc();
  }

  setApprovalDateFunc() {
    const today = new Date();
    this.maxApprovalDate.setDate(today.getDate());
    this.setApprovalDate.setDate(today.getDate());
    this.approveCcdForm.patchValue({
      approvedAt: new Date(this.setApprovalDate)
    });
  }

  selectedApprovalDate(event: any) {
    console.log(event)
    this.approveCcdForm.patchValue({ approvedAt: event });
  }

  createApproveCcdForm() {
    this.approveCcdForm = new FormGroup({
      isApprovedOnBehalf: new FormControl(false),
      approverName: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      approvalNotes: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]),
      approvedAt: new FormControl('')
    });
  }

  onBehalfOfClick(event: any) {
    this.approveCcdForm.patchValue({ isApprovedOnBehalf: event.target.checked });

    const approverName = this.approveCcdForm.get('approverName');
    if(event.target.checked){
      if (approverName) {
        approverName.setValidators([Validators.required,Validators.minLength(3),Validators.maxLength(100)]);
      }
    }
    else{
      this.approveCcdForm.patchValue({ approverName: '' });
      if (approverName) {
        approverName.setValidators([Validators.minLength(3),Validators.maxLength(100)]);
      }
    }
    approverName?.updateValueAndValidity();
    console.log('check', this.approveCcdForm)
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.approveCcdForm);
    return errorMsg;
  }

  setUploadedDocuments(event:any){
    this.uploadedDocuments.push(event?.docId);
  }
  @ViewChild(UploadFilesComponent) uploadFilesComponentRef!: UploadFilesComponent;
  closeModalPopUp() {
    this.onButtonClick.emit({})
    const uploadComponent = this.uploadFilesComponentRef;
    if (uploadComponent) {
      this.uploadedDocuments.forEach((docId:string) => {
        uploadComponent.deleteFile(docId, true, true);
      });
    }
  }

  trimFormFields() {
    Object.keys(this.approveCcdForm.controls).forEach(key => {
      const control = this.approveCcdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  submitApproveCcdForm() {
    console.log('submit 0', this.approveCcdForm.value);
    if (this.approveCcdForm.valid) {
      this.trimFormFields();
      const reqData = {
        ccdNo: this.ccdData?.ccdNo,
        approveData: this.approveCcdForm.value
      };
      console.log('submit 1',reqData);
      this.submitLoading = true;
      this.ccdService.setReviewScreenAutoSaveMessage('saving');
      this.ccdService.approveCcd(reqData).subscribe({
        next: () => {
          this.submitLoading = false;
          this.onButtonClick.emit({
            isCcdApproved: true
          });
          this.ccdService.setReviewScreenAutoSaveMessage('saved');
        },
        error: (error) => {
          console.error('Error approving ccd:', error);
          this.submitLoading = false;
          this.ccdService.setReviewScreenAutoSaveMessage('not-saved');
        }
      });
    }
  }
}
