export const TABS = {
    "active": {
        "label": "Active",
        "number_of_records": 0,
        "active": true,
        "value": "active",
        "access": ["1", "2", "3"]
    },
    "upcoming": {
        "label": "Upcoming",
        "number_of_records": 0,
        "active": false,
        "value": "upcoming",
        "access": ["1", "2", "3"]
    },
    "completed": {
        "label": "Completed",
        "number_of_records": 0,
        "active": false,
        "value": "completed",
        "access": ["1", "2", "3"]
    },
    "cancelled": {
        "label": "Cancelled",
        "number_of_records": 0,
        "active": false,
        "value": "cancelled",
        "access": ["1", "2", "3"]
    },
    "draft": {
        "label": "Draft",
        "number_of_records": 0,
        "active": false,
        "value": "draft",
        "access": ["1","2"]
    }
}