import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private isNavigationCollapse$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private headerDataSubject = new BehaviorSubject({});

  constructor(private titleService: Title) { }

  setPageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  isNavigationCollapseSet(val: boolean) {
    this.isNavigationCollapse$.next(val);
  }
  isNavigationCollapseGet() {
    return this.isNavigationCollapse$.asObservable();
  }
  isNavigationCollapseByValue() {
    return this.isNavigationCollapse$.getValue();
  }

  setHeaderData(value: any) {
    this.headerDataSubject.next(value);
  }
  getHeaderData() {
    return this.headerDataSubject;
  }
}
