import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { roleGuard } from './guards/role.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { CreateCcdStep1Component } from './pages/create-ccd-step1/create-ccd-step1.component';
import { CreateCcdStep2Component } from './pages/create-ccd-step2/create-ccd-step2.component';
import { ReviewAndApproveComponent } from './pages/review-and-approve/review-and-approve.component';
import { NavComponent } from './components/nav/nav.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: NavComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', component: DashboardComponent },
      { 
        path: 'dashboard',
        children: [
          { path: '', component: DashboardComponent },
          { path: 'create-ccd-step1', component: CreateCcdStep1Component, canActivate: [roleGuard] },
          { path: 'create-ccd-step2', component: CreateCcdStep2Component, canActivate: [roleGuard] },
        ]
      },
      { path: 'review-ccd', component: ReviewAndApproveComponent },
      { path: 'manage-users', component: ManageUsersComponent },
    ]
  }
];