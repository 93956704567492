export const ROLES = {
    'Manager' : "1",
    'Owner' : "2",
    'DepartmentUser' : "3",
    'Other' : "4"
}
export const TYPEOFCOA = {
    NEW_COA: 'New COA',
    NEW_RATES_AND_TERMS: 'New rates and terms',
    FINANCIAL_NEGOTIATIONS: 'Financial negotiations',
    OPTIONAL_YEARS: 'Optional years',
}
export const TYPEOFCOA_FOR_EDIT = {
    NEW_COA: {
        name: 'New COA',
        enabledIn : ['NEW_COA', 'NEW_RATES_AND_TERMS', 'FINANCIAL_NEGOTIATIONS', 'OPTIONAL_YEARS']
    },
    NEW_RATES_AND_TERMS: {
        name: 'New rates and terms',
        enabledIn : ['NEW_RATES_AND_TERMS', 'FINANCIAL_NEGOTIATIONS', 'OPTIONAL_YEARS']
    },
    FINANCIAL_NEGOTIATIONS: {
        name: 'Financial negotiations',
        enabledIn : ['FINANCIAL_NEGOTIATIONS', 'OPTIONAL_YEARS']
    },
    OPTIONAL_YEARS: {
        name: 'Optional years',
        enabledIn : ['OPTIONAL_YEARS']
    },
}

export const LAW = {
    USA: 'USA',
    UK: 'UK',
    Other: 'Other',
}

export const SERVICE = {
    STJS: 'STJS',
    SNICS: 'SNICS',
    SNAPS: 'SNAPS',
}

export const DIVISIONS = {
    COMMERCIAL: 'Commercial',
    LEGAL: 'Legal',
    ACCOUNTING: 'Accounting',
    OPERATIONS_MANAGER: 'Operations manager',
    TANKER: 'Tanker controller',
    DEMURRAGE: 'Demurrage',
    GROUP_RISK: 'Group risk',
    OTHER: 'Other',
}

export const VALIDATION_MESSAGE = Object.freeze({
    required: 'This is a required field.',
    onlyNumbers: 'Only numbers are allowed',
    minMaxVolume: 'Input must not be higher than the maximum value in Estimated Annual Volume.',
    minMaxFreight: 'Input must not be higher than the maximum value in Estimated Annual Freight.'
})
export const customizedValidationMessages = (type: string, num:number) => {
    let message = '';
    switch (type) {
        case 'minlength':
            message = `Please enter at least ${num} characters.`;
            break;
        case 'maxlength':
            message = `Value must not be greater than ${num} characters long.`;
            break;
        default:
            break;
    }
    return `${message}`;
}