<section class="DetailsWrapMain">
    <!-- Left Card CCD Preview Details  -->
    <div class="InfoCardLftHolder">
        @if (ccdRes) {
        <div class="InfoCardLft">
            <!-- Preview Details  -->
            <h2 class="sub-heading-s1 EditBtn">
                Preview - CCD details
                <snl-button [type]="'tertiary'" [size]="'small'" [icon]="'fa fa-pencil'" (buttonClick)="editDetails()">
                    Edit
                </snl-button>
            </h2> 
             <ul class="OptionsList">
                <li>
                    <span class="body-b LeftCell">Type of COA</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{typeOfCoa[ccdRes?.coaType]}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Due date</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.dueBy | date: 'dd MMM yyyy' }}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Counterparty</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.customer}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Contract period</span>
                    <span class="section-title" *ngIf="ccdRes?.coaStartDate && ccdRes?.coaEndDate" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.coaStartDate | date: 'dd MMM yyyy' }} - {{ccdRes?.coaEndDate | date: 'dd MMM yyyy' }}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Line of business</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{extractLob(ccdRes?.lob)}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Service</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.service}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Product</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.product}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Estimated annual volume</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.estAnnualVolume}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Estimated annual freight</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.estAnnualFreight}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Charter party form</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.chartererPartyForm}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Law</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.law}}</span>
                </li>
                <li>
                    <span class="body-b LeftCell">Manager</span>
                    <span class="section-title" [ngClass]="{'Stolt-Skeleton':fetchDataLoading}">{{ccdRes?.manager}}</span>
                </li>
            </ul> 

            <!-- Document(s) -->
             <h2 class="sub-heading-s1">Document(s)</h2> 
             <ul class="OptionsList DocumentList body-b1">
                <span class="body-b1">NA</span>
            </ul> 

            <!-- CCD reference -->
             <h2 class="sub-heading-s1">CCD reference</h2> 
            <!-- Validated Reference CCD  -->
            @if(ccdRes?.ccdRefField)
            {
            <div class="ValidatedMsg body-b1">
                <svg-icon class="df-c" src="assets/icons/completed.svg"></svg-icon>
                <span>CCD# <strong>{{ccdRes?.ccdRefField}}</strong></span> 
            </div>
            }
            @else{
                <span class="body-b1">NA</span>
            } 
        </div>
        }
    </div>
    
    <!-- Right Card CCD Departments’ notes  -->
    <div class="InfoCardLft InfoCardRgt">
        <h2 class="sub-heading-s1">Departments’ notes</h2>
        <p class="body-b SubHeadingBtm">Enter notes for all the departments</p>
        <form [formGroup]="ccdForm">
            <ul>
                @if (fetchDataLoading) {
                <li class="Stolt-Skeleton" *ngFor="let number of [0,1,2,3,4,5,6]"></li>
                }
                @else if(visibleFields && visibleFields.length > 0) {
                <li *ngFor="let field of visibleFields" [ngClass]="showError(field?.fieldName) ? 'ErrorSpaceBottom' : ''">
                    <span class="body-b2 FieldTitle">{{field?.header}}</span>
                    <snl-input [label]="'Notes'" [formControlName]="field?.fieldName" [errorMessage]="showError(field?.fieldName)" (focusout)="autoSave(field?.fieldName)"></snl-input>
                </li>
                }
                @else {
                    <span class="body-b1">NA</span>
                }
            </ul>
        </form>   
    </div>
</section>

<!-- Sticky Footer Bottom Action Panel -->
<footer class="FixedFooterBar">
    <snl-action-panel>
        <div class="gap16 df-c" left-side>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': submitLoading}" [size]="'large'" (buttonClick)="onSubmit()" [disabled]="!areAllRequiredFieldsFilled()">Submit</snl-button>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': saveExitLoading}" [size]="'large'" [type]="'secondary'" (buttonClick)="saveAndExit()">Save & exit</snl-button>
        </div>
        <div right-side>
            <snl-button [type]="'tertiary'" (buttonClick)="deleteCcdPopup()">Delete</snl-button>
        </div>
    </snl-action-panel>
</footer>
@if(openDeleteDraftPopup) {
    <app-delete-new-ccd-creation [open]="openDeleteDraftPopup" (onButtonClick)="deleteCcd($event)"></app-delete-new-ccd-creation>
}