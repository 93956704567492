import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule, ModalModule } from 'snl-complib';

@Component({
  selector: 'app-cancel-edit-ccd-popup',
  standalone: true,
  imports: [ModalModule, ButtonModule, NgClass],
  templateUrl: './cancel-edit-ccd-popup.component.html',
  styleUrl: './cancel-edit-ccd-popup.component.scss'
})
export class CancelEditCcdPopupComponent {
  disableSaveButton:boolean = true;
  showErrorMessage: boolean = false;
  modalSettings: any;

  @Input() open: boolean = false;
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  ngOnInit(){
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
  }

  closeModalPopUp() {
    this.onButtonClick.emit({}) 
  }

  cancelEditCcdAndCloseModalPopUp() {
      this.onButtonClick.emit({
        isCancelCcd: true
      });
  }
}
