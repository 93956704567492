export const environment = {
  production: true,
  type: 'dev',
  BASE_URL: 'https://api.stoltdev.com/ccd-api/',
  clientId: "3a989bdd-6c23-4670-9ba1-d4f1f451c355",
  authority: "https://login.microsoftonline.com/74437d1e-19e1-4555-bfcb-c6d8bace6725",
  redirectUri: "https://ccd-dev.stolttankers.com",
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  BACKEND_URL: 'https://api.stoltdev.com/ccd-api/',
  USERSCOPE: 'api://ac39c76a-d34f-4bae-a66d-f3b818bef8f0/.default'
};