
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, DatePickerModule, DropdownModule, InputModule, LinkModule, PageTitleModule, SwitchModule } from 'snl-complib';
import { CcdService } from 'src/app/services/ccd.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ROLES, TYPEOFCOA } from 'src/app/constant/constant';
import { DEPARTMENTS } from 'src/app/constant/table-headings';
import { DeleteNewCcdCreationComponent } from 'src/app/components/delete-new-ccd-creation/delete-new-ccd-creation.component';
import { ValidationService } from 'src/app/services/validation.service';
import { forkJoin } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { customizedMessages, Toasts } from 'src/app/constant/toast.messages';
import { PreviewCcdDetailsComponent } from "../../components/preview-ccd-details/preview-ccd-details.component";
import { AuthService } from 'src/app/services/auth.service';
import { ActivateDepartmentComponent } from 'src/app/components/activate-department/activate-department.component';

@Component({
  selector: 'app-create-ccd-step2',
  standalone: true,
  imports: [DeleteNewCcdCreationComponent, CommonModule, ReactiveFormsModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, PreviewCcdDetailsComponent, SwitchModule, ActivateDepartmentComponent],
  templateUrl: './create-ccd-step2.component.html',
  styleUrls: ['./create-ccd-step2.component.scss']
})
export class CreateCcdStep2Component implements OnInit {
  public ccdRes: any = {};
  public ccdForm: FormGroup = new FormGroup({});
  public savedFormObj: any = [];
  public ownerAddedDocuments: any = [];
  private ccdNo: string | null = null;
  public typeOfCoa: any = TYPEOFCOA;
  public selectedCoaType: string = 'NEW_COA';
  public departmentFields: any[] = [];
  public openDeleteDraftPopup: boolean = false;
  public openActivateDepartmentPopup: boolean = false;
  public submitLoading: boolean = false;
  public saveExitLoading: boolean = false;
  public deleteLoading: boolean = false;
  public fetchDataLoading: boolean = false;
  public editButtonRoute: string = '';
  public disableManagementSwitch: boolean = false;
  public autoSaveMessage: string = 'saved'; //not-saved, saved, saving
  public currentUser: any = {};
  public roles: any = ROLES;
  public newlyAddedDepartments: Array<string> = [];
  public isFormValid: boolean = false;

  constructor(
    private ccdService: CcdService,
    private toasterService: ToasterService,
    private validationService: ValidationService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.ccdNo = this.route.snapshot.queryParamMap.get('ccdNo');
    if (this.ccdNo) {
      this.fetchCurrentUserData();
      this.fetchData();
      this.editButtonRoute = `/dashboard/create-ccd-step1?ccdNo=${this.ccdNo}`;
      //set header data
      let queryParamsString: string = `?ccdNo=${this.ccdNo}`;
      const headerData = {
        headerTitle: 'Create CCD',
        backButtonNavigateTo: `dashboard/create-ccd-step1${queryParamsString}`,
        breadCrumbList: [
          { id: '1', label: 'Dashboard', navigateTo: 'dashboard', isClickable: true },
          { id: '3', label: 'Create CCD', isClickable: false }
        ]
      }
      this.navigationService.setHeaderData(headerData);

      this.ccdForm.valueChanges.subscribe(() => {
        this.checkFormValidity();
      });
    }
  }

  isOwner() {
    return this.currentUser?.role === this.roles['Owner'];
  }
  isManager() {
    return this.currentUser?.role === this.roles['Manager'];
  }

  fetchCurrentUserData() {
    this.authService.getBackendUserData().subscribe((data: any) => {
      if (data) {
        this.currentUser = data;
      }
    });
  }

  fetchData() {
    const fetchCcdData$ = this.ccdService.fetchCcdData({ ccdNo: this.ccdNo });
    const fetchCcdNotes$ = this.ccdService.fetchCcdNotes({ ccdNo: this.ccdNo, department: 'OWNER' });

    this.fetchDataLoading = true;
    forkJoin([fetchCcdData$, fetchCcdNotes$]).subscribe({
      next: ([ccdData, ccdNotes]: any) => {
        if (ccdData?.ccdRes[0].ccdStatus !== 'draft' && !ccdData?.ccdRes[0].isReactivating) {
          this.router.navigate(['/dashboard']);
          return;
        }
        this.ccdRes = ccdData?.ccdRes?.[0];
        if (this.ccdRes?.coaType === 'OPTIONAL_YEARS') {
          this.checkFormValidity();
        }
        this.ownerAddedDocuments = ccdData?.ownerAddedDocuments;
        if (this.ccdRes?.coaType === 'NEW_RATES_AND_TERMS' && this.ccdRes?.isNewClause === true) {
          this.selectedCoaType = 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE';
        } else {
          this.selectedCoaType = this.ccdRes?.coaType;
        }
        this.makeCcdForm();
        this.populateCcdNotes(ccdNotes?.notes);
        this.fetchDataLoading = false;
      },
      error: (error) => {
        this.fetchDataLoading = false;
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching data:', error);
      }
    });
  }

  makeCcdForm() {
    let departmentList: any = [];
    if (this.isManager()) {
      if (this.authService?.isDepartmentalDependency) {
        departmentList = DEPARTMENTS;
      }
      else {
        departmentList = DEPARTMENTS.filter((field: any) => field.visibleIn.includes(this.selectedCoaType));
      }
    }
    else if (this.isOwner()) {
      departmentList = DEPARTMENTS.filter((field: any) => field.visibleIn.includes(this.selectedCoaType));
    }

    this.departmentFields = departmentList.map((field: any) => ({
      ...field,
      switchState: field.visibleIn.includes(this.selectedCoaType),
      realTimeSwitchState: field.visibleIn.includes(this.selectedCoaType),
    }));

    const formControls: { [key: string]: FormControl } = {};
    this.departmentFields.forEach(field => {
      const initialNote = field?.initialNotes?.[this.selectedCoaType] || '';
      formControls[field.fieldName] = new FormControl(initialNote, [Validators.minLength(3), Validators.maxLength(100)]);
    });
    Object.keys(formControls).forEach(key => {
      this.ccdForm.addControl(key, formControls[key]);
    });
  }

  populateCcdNotes(ccdNotesObj: any = {}) {
    Object.keys(ccdNotesObj).forEach(key => {
      if (this.ccdForm.get(key) && (!this.ccdRes?.initiatedFrom || this.ccdNo)) {
        this.ccdForm.get(key)?.patchValue(this.checkHTMLTags(ccdNotesObj?.[key]?.notes) ? '' : ccdNotesObj?.[key]?.notes);
        //this.ccdForm.get(key)?.patchValue(ccdNotesObj?.[key]?.notes);

        this.departmentFields = this.departmentFields.map((field: any) => {
          if (field.fieldName === key) {
            field.realTimeSwitchState = true;
          }
          return field;
        });
      }
    });
    this.savedFormObj = this.ccdForm.value;
  }

  checkHTMLTags(str: string) {  
    const regex = /<[^>]+>/;
    return regex.test(str); 
  }

  inputChanged(): void {
    const currentFormValues = this.ccdForm.value;
    const isFormChanged = JSON.stringify(this.savedFormObj) !== JSON.stringify(currentFormValues);

    if (isFormChanged) {
      this.autoSaveMessage = 'not-saved';
    } else {
      this.autoSaveMessage = 'saved';
    }
  }

  trimFormFields() {
    Object.keys(this.ccdForm.controls).forEach(key => {
      const control = this.ccdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.ccdForm);
    return errorMsg;
  }

  autoSave(field: string) {
    this.trimFormFields();
    const fieldValue = this.ccdForm.get(field)?.value;
    if (this.ccdForm.valid && fieldValue !== '' && fieldValue !== this.savedFormObj[field]) {
      const reqData = {
        ccdNo: this.ccdNo,
        departments: this.ccdForm?.value,
        type: 'draft'
      };
      this.autoSaveMessage = 'saving';
      this.ccdService.createCcdStep2(reqData).subscribe({
        next: ({ createdContract }: any) => {
          this.ccdForm.get('ccdNo')?.patchValue(createdContract?.ccdNo);
          this.savedFormObj = this.ccdForm.value;
          this.autoSaveMessage = 'saved';
        },
        error: (error) => {
          this.autoSaveMessage = 'not-saved';
          console.error('Error creating/updating draft CCD:', error);
        }
      });
    }
  }

  saveAndExit() {
    this.trimFormFields();
    if (this.ccdForm.valid) {
      const reqData = {
        ccdNo: this.ccdNo,
        departments: this.ccdForm?.value,
        type: 'draft'
      };
      this.saveExitLoading = true;
      this.ccdService.createCcdStep2(reqData).subscribe({
        next: () => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
          this.storageService.putDataInStorage('selectedTab', 'draft');
          this.router.navigate(['/dashboard']);
          this.saveExitLoading = false;
        },
        error: (error) => {
          this.saveExitLoading = false;
          if (error?.status === 424)
            this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
          else
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          console.error('Error updating draft CCD:', error);
        }
      });
    }
    else {
      this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
      this.storageService.putDataInStorage('selectedTab', 'draft');
      this.router.navigate(['/dashboard']);
    }
  }

  checkFormValidity(): void {
    const allMandatoryFieldsFilled = this.departmentFields.filter(field => field?.realTimeSwitchState).every(field => {
      const control = this.ccdForm.get(field?.fieldName);
      return control && control.value && control.valid;
    });
    this.isFormValid = this.isDataPresentInEachCcdField() && allMandatoryFieldsFilled && this.ccdForm.valid;
  }

  isDataPresentInEachCcdField(): boolean {
    const ccdFields = ["ccdNo", "coaType", "dueBy", "customer", "lob", "service", "product", "estAnnualVolumeMin", "estAnnualFreightMin", "estAnnualVolumeMax", "estAnnualFreightMax", "chartererPartyForm", "law", "manager", "managerId", "coaStartDate", "coaEndDate"];
    return ccdFields.every(field => {
      const value = this.ccdRes[field];
      return value || value === 0;
    });
  }

  truncateString(value: string, limit: number): string {
    if (!value) return '';
    return value.length > limit ? value.substring(0, limit) + '...' : value;
  }

  submitPopup() {
    if (this.newlyAddedDepartments?.length > 0) {
      this.openActivateDepartmentPopup = true;
    }
    else {
      this.onSubmit({ isActivateDepartment: true });
    }
  }
  onSubmit(event: any) {
    console.log('submit', this.ccdForm.value, event);
    this.openActivateDepartmentPopup = false;
    if (event?.isActivateDepartment && this.ccdForm.valid) {
      this.trimFormFields();
      const reqData = {
        ccdNo: this.ccdNo,
        departments: this.ccdForm?.value,
        submittedAt: this.ccdService.getLocalISOTime(new Date())
      };
      this.submitLoading = true;
      this.ccdService.submitCcd(reqData).subscribe({
        next: ({ ccdNo }: any) => {
          this.toasterService.openToastCcd(Toasts.Types.Success, customizedMessages('submit', ccdNo, this.truncateString(this.ccdRes?.customer, 20)), Toasts.Actions.Submit.Title);
          this.storageService.putDataInStorage('selectedTab', 'active');
          this.router.navigate(['/dashboard']);
          this.submitLoading = false;
        },
        error: (error) => {
          console.error('Error creating/updating CCD:', error);
          if (error?.status === 424)
            this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
          else
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Submit.Error);
          this.submitLoading = false;
        }
      });
    }
  }

  deleteCcdPopup() {
    this.openDeleteDraftPopup = true;
  }
  
  deleteCcd(event: any) {
    this.openDeleteDraftPopup = false;
    if (event?.isDeleteCcd) {
      if (this.ccdNo) {
        const reqData = {
          ccdNo: this.ccdNo,
          type: "discard",
          updateValue: ''
        }
        this.deleteLoading = true;
        this.ccdService.updateContractStatus(reqData).subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
            this.router.navigate(['/dashboard']);
            this.deleteLoading = false;
          },
          error: (error) => {
            console.error('Error deleting CCD:', error);
            if (error?.status === 424)
              this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
            else
              this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DeleteCcd.Error);
            this.deleteLoading = false;
          }
        });
      }
      else {
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
        this.router.navigate(['/dashboard']);
      }
    }
  }

  departmentSwitchClick(event: any, fieldName: string) {
    const formControl = this.ccdForm.get(fieldName);
    if (formControl) {
      if (event?.isSwitchActive) {
        this.newlyAddedDepartments.push(fieldName);
        formControl?.addValidators([Validators.minLength(3), Validators.maxLength(100)]);
      } else {
        const index = this.newlyAddedDepartments.indexOf(fieldName);
        if (index > -1) {
          this.newlyAddedDepartments.splice(index, 1); // Remove 1 element at the found index
        }
        formControl?.patchValue('');
        formControl?.clearValidators();
      }
      formControl?.updateValueAndValidity();
    }
    const departmentFields = JSON.parse(JSON.stringify(this.departmentFields));
    this.departmentFields = [];
    this.departmentFields = departmentFields.map((field: any) => {
      if (field.fieldName === fieldName) {
        field.realTimeSwitchState = event?.isSwitchActive;
      }
      return field;
    });
    this.checkFormValidity();
  }
}