import { CommonModule, Location } from '@angular/common';
import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, CheckboxModule, DatePickerModule, DropdownModule, FileUploadModule, InputModule, LinkModule, ModalModule, PageTitleModule } from 'snl-complib';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TYPEOFCOA } from 'src/app/constant/constant';
import { CcdService } from 'src/app/services/ccd.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { StorageService } from 'src/app/services/storage.service';
import { finalize } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteNewCcdCreationComponent } from 'src/app/components/delete-new-ccd-creation/delete-new-ccd-creation.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { Toasts } from 'src/app/constant/toast.messages';

@Component({
  selector: 'app-create-ccd-step1',
  standalone: true,
  imports: [DeleteNewCcdCreationComponent, CommonModule, ReactiveFormsModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, ModalModule, FileUploadModule, CheckboxModule
  ],
  templateUrl: './create-ccd-step1.component.html',
  styleUrls: ['./create-ccd-step1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCcdStep1Component {
  open: boolean = false;
  public ccdForm!: FormGroup;
  public savedFormObj: any = [];
  public isCcdRefCheckClicked: boolean = false;
  public coaTypeOptions: any;
  public lobOptions: any = [];
  public managerOptions: any = [];
  private typeOfCoa: any = TYPEOFCOA;
  public setDueDate: Date = new Date();
  public minDueDate: Date = new Date();
  public maxDueDate: Date = new Date();
  public setContractStartDate: Date = new Date();
  public setContractEndDate: Date = new Date();
  public minContractDate: Date = new Date();
  public isCcdRefValidated: boolean | undefined = undefined;
  public openDeleteDraftPopup: boolean = false;
  public deleteCcdNo: string = '';
  public ccdNo: any;
  public coaTypeObj: any = [];
  public managerObj: any = [];
  public lobObj: any = [];
  public isCcdRefDisabled: boolean = true;
  public isAllowedToDeleteAndSaveExit: boolean = false;
  public isNewClauseChecked: boolean = false;
  public nextLoading: boolean = false;
  public saveExitLoading: boolean = false;

  constructor(
    private ccdService: CcdService,
    private validationService: ValidationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toasterService: ToasterService,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.makeCcdForm();
    this.fetchTypeOfCoaOptions();
    this.fetchLobOptions();
    this.fetchManagerOptions();
    this.route.queryParams.subscribe((params) => {
      this.ccdNo = params['ccdNo'];
      if (this.ccdNo) {
        this.fetchCCDData();
      } else {
        this.setDates();
        //set header data
        const headerData = {
          headerTitle: 'Start a new CCD',
          backButtonNavigateTo: 'dashboard',
          breadCrumbList: [
            { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
            { id: '3', label: 'Start a new CCD', isClickable: false }
          ]
        }
        this.navigationService.setHeaderData(headerData);
      }

    });
  }

  makeCcdForm() {
    this.ccdForm = new FormGroup({
      ccdNo: new FormControl('', [Validators.minLength(3)]),
      coaType: new FormControl(''),
      dueBy: new FormControl(''),
      customer: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      lob: new FormControl([]),
      service: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      product: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      estAnnualVolume: new FormControl('', [Validators.minLength(3), Validators.maxLength(50)]),
      estAnnualFreight: new FormControl('', [Validators.minLength(3), Validators.maxLength(50)]),
      chartererPartyForm: new FormControl('', [Validators.minLength(3), Validators.maxLength(36)]),
      law: new FormControl('', [Validators.minLength(3), Validators.maxLength(36)]),
      manager: new FormControl(''),
      managerId: new FormControl(''),
      ccdRefField: new FormControl('', [Validators.minLength(7), Validators.maxLength(36)]),
      ccdRef: new FormControl(''),
      coaStartDate: new FormControl(''),
      coaEndDate: new FormControl(''),
      isNewClause: new FormControl(false)
    });
  }

  setDates() {
    const today = new Date();
    this.minDueDate.setDate(today.getDate() + 6);
    this.maxDueDate.setDate(today.getDate() + 42);
    this.setDueDate.setDate(today.getDate() + 7);

    this.minContractDate.setDate(today.getDate());
    this.setContractStartDate.setDate(today.getDate());
    this.setContractEndDate.setDate(today.getDate() + 30);

    this.ccdForm.patchValue({
      dueBy: new Date(this.setDueDate).toISOString(),
      coaStartDate: new Date(this.setContractStartDate).toISOString(),
      coaEndDate: new Date(this.setContractEndDate).toISOString(),
    });
  }

  fetchTypeOfCoaOptions() {
    this.coaTypeOptions = Object.keys(this.typeOfCoa).map(key => ({
      id: key,
      name: this.typeOfCoa[key]
    }));
  }
  fetchLobOptions() {
    this.ccdService.fetchLobList().subscribe({
      next: ({ lobList }: any) => {
        this.lobOptions = lobList;
      },
      error: (error) => {
        console.error('Error fetching Lob options:', error);
      }
    });
  }
  fetchManagerOptions() {
    this.ccdService.fetchManagersData().subscribe({
      next: ({ userData }: any) => {
        this.managerOptions = userData.map((user: any) => ({
          id: user?.userId,
          name: user.name
        }));
      },
      error: (error) => {
        console.error('Error fetching Manager options:', error);
      }
    });
  }

  populateCcdData(ccdObj: any) {
    if (ccdObj.coaType) {
      this.coaTypeObj = [{
        "id": ccdObj.coaType,
        "name": this.typeOfCoa[ccdObj.coaType]
      }];
    }
    if (ccdObj.lob.length > 0) {
      this.lobObj = ccdObj.lob.map((val: any) => ({ "id": val.split("(")[1].split(")")[0], "name": val }));
    }
    if (ccdObj.managerId) {
      this.managerObj = [{ "id": ccdObj.managerId, "name": ccdObj.manager }];
    }

    this.setDueDate = new Date(ccdObj.dueBy);
    this.setContractStartDate = new Date(ccdObj.coaStartDate);
    this.setContractEndDate = new Date(ccdObj.coaEndDate);
    this.isNewClauseChecked = ccdObj.isNewClause;
    this.isCcdRefValidated = ccdObj?.ccdRef ? true : undefined;
    let ccdRefField = ccdObj?.ccdRefField ? ccdObj.ccdRefField : '';

    let obj = {
      ccdNo: ccdObj.ccdNo,
      coaType: ccdObj.coaType,
      dueBy: new Date(ccdObj.dueBy),
      customer: ccdObj.customer,
      lob: ccdObj.lob,
      service: ccdObj.service,
      product: ccdObj.product,
      estAnnualVolume: ccdObj.estAnnualVolume,
      estAnnualFreight: ccdObj.estAnnualFreight ?? "",
      chartererPartyForm: ccdObj.chartererPartyForm,
      law: ccdObj.law,
      manager: ccdObj.manager,
      managerId: ccdObj.managerId,
      ccdRefField: ccdRefField,
      ccdRef: ccdObj.ccdRef,
      coaStartDate: new Date(ccdObj.coaStartDate),
      coaEndDate: new Date(ccdObj.coaEndDate),
      isNewClause: ccdObj.isNewClause,
    };

    this.ccdForm.patchValue(obj);
  }

  fetchCCDData() {
    this.ccdService.fetchCcdData({ "ccdNo": this.ccdNo }).subscribe({
      next: ({ ccdRes }: any) => {
        if (ccdRes && ccdRes.length > 0) {
          this.populateCcdData(ccdRes[0]);
          this.isAllowedToDeleteAndSaveExit = true;
          this.setCreateCCDHeader();
        }
      },
      error: (error) => {
        console.error('Error fetching Manager options:', error);
        this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.err);
        if ([401, 404].includes(error?.status)) {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }
  
  setCreateCCDHeader(){
    //set header data
    const headerData = {
      headerTitle: 'Create CCD',
      backButtonNavigateTo: 'dashboard',
      breadCrumbList: [
        { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
        { id: '3', label: 'Create CCD', isClickable: false }
      ]
    }
    this.navigationService.setHeaderData(headerData);
  }

  applySelectedCoaType(event: any) {
    this.ccdForm.patchValue({ coaType: event?.selectedRecords?.id });
    this.autoSave('coaType');
  }

  resetCoaType() {
    this.ccdForm.patchValue({ coaType: '' });
    this.autoSave('coaType');
  }

  applySelectedLob(event: any) {
    const selectedLobNames = event?.selectedRecords?.map((record: any) => record.name) || [];
    this.ccdForm.patchValue({ lob: selectedLobNames });
    this.autoSave('lob');
  }

  resetLob() {
    this.ccdForm.patchValue({ lob: [] });
    this.autoSave('lob');
  }

  applySelectedManager(event: any) {
    this.ccdForm.patchValue({ 'managerId': event?.selectedRecords?.id });
    this.ccdForm.patchValue({ 'manager': event?.selectedRecords?.name });
    this.autoSave('manager');
  }

  resetManager() {
    this.ccdForm.patchValue({ 'managerId': '' });
    this.ccdForm.patchValue({ 'manager': '' });
    this.autoSave('manager');
  }

  selectedDueDate(event: any) {
    const utcDate = new Date(event).toISOString();
    this.ccdForm.patchValue({ dueBy: utcDate });
    this.autoSave('dueBy');
  }

  selectedContractPeriod(event: any) {
    const startDateUtc = new Date(event?.startDate).toISOString();
    const endDateUtc = new Date(event?.endDate).toISOString();
    this.ccdForm.patchValue({ coaStartDate: startDateUtc });
    this.ccdForm.patchValue({ coaEndDate: endDateUtc });
    this.autoSave('coaStartDate');
  }

  withNewClauseClick(event: any) {
    this.ccdForm.patchValue({ isNewClause: event.target.checked });
    this.autoSave('isNewClause');
  }

  ccdRefChange() {
    console.log('ccdRefChange', this.ccdForm.get('ccdRefField')?.value, this.ccdForm.get('ccdRefField')?.valid);
    if (this.ccdForm.get('ccdRefField')?.invalid || (this.ccdForm.get('ccdRefField')?.value === '')) {
      this.isCcdRefDisabled = true;
    }
    else {
      this.isCcdRefDisabled = false;
    }
  }

  checkCcdRef() {
    this.trimFormFields();
    console.log('checkCcdRef', this.ccdForm.get('ccdRefField')?.valid);
    this.isCcdRefCheckClicked = true;
    const reqData = {
      ccdNo: this.ccdForm.get('ccdRefField')?.value
    }
    this.ccdService.fetchCcdReference(reqData).pipe(
      finalize(() => {
        this.isCcdRefCheckClicked = false;
      })
    ).subscribe({
      next: ({ ccdRes }: any) => {
        console.log('check validated')
        this.isCcdRefValidated = true;
        this.isCcdRefDisabled = true;
        this.ccdForm.get('ccdRefField')?.patchValue(ccdRes?.ccdNo + ' ' + ccdRes?.customer);
        this.ccdForm.get('ccdRef')?.patchValue(ccdRes?.ccdNo);
        this.autoSave('ccdRef');
      },
      error: (error) => {
        this.isCcdRefValidated = false;
        this.ccdForm.get('ccdRef')?.patchValue('');
        console.error('Error validating CCD:', error);
      }
    });
  }

  deleteCcdRefField() {
    const { ccdRefField, ...newObj } = this.ccdForm.value;
    return newObj;
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.ccdForm);
    return errorMsg;
  }

  trimFormFields() {
    Object.keys(this.ccdForm.controls).forEach(key => {
      const control = this.ccdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  autoSave(field: string) {
    this.trimFormFields();
    const fieldValue = this.ccdForm.get(field)?.value;
    console.log('Auto save 0', this.ccdForm, fieldValue);
    if (this.ccdForm.valid && this.isNotEmptyStringOrArray(fieldValue) && this.isFieldValueChanged(field)) {
      console.log('Auto save 1', this.ccdForm);
      const formObj = this.deleteCcdRefField();
      const reqData = {
        contractData: formObj,
        type: "saveDraft"
      };
      this.ccdService.createCcd(reqData).subscribe({
        next: ({ createdContract }: any) => {
          this.ccdForm.get('ccdNo')?.patchValue(createdContract?.ccdNo);
          this.location.replaceState(`/dashboard/create-ccd-step1?ccdNo=${createdContract?.ccdNo}`);
          this.savedFormObj = this.ccdForm.value;
          this.isAllowedToDeleteAndSaveExit = true;
          this.setCreateCCDHeader();
        },
        error: (error) => {
          console.error('Error creating/updating draft CCD:', error);
        }
      });
    }
  }

  isFieldValueChanged(field: string) {
    if (field === 'lob') {
      return !this.areArraysEqual(this.ccdForm.get(field)?.value, this.savedFormObj[field]);
    } else {
      return this.ccdForm.get(field)?.value !== this.savedFormObj[field];
    }
  }

  isNotEmptyStringOrArray(fieldValue: any): boolean {
    if (typeof fieldValue === 'string') {
      return fieldValue !== '';
    } else if (Array.isArray(fieldValue)) {
      return fieldValue.length > 0;
    }
    return true;
  }

  areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    for (let i = 0; i < arr1?.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  saveAndExit() {
    console.log('save and exit 0', this.ccdForm);
    if (this.ccdForm.valid) {
      this.trimFormFields();
      console.log('save and exit 1');
      const formObj = this.deleteCcdRefField();
      const reqData = {
        contractData: formObj,
        type: "saveDraft"
      };
      this.saveExitLoading = true;
      this.cdr.detectChanges();
      this.ccdService.createCcd(reqData).subscribe({
        next: () => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
          this.storageService.putDataInStorage('selectedTab', 'draft');
          console.log('save and exit 2', this.storageService.getDataFromStorage('selectedTab'));
          this.router.navigate(['/dashboard']);
          this.saveExitLoading = false;
        },
        error: (error) => {
          console.error('Error updating draft CCD:', error);
          this.saveExitLoading = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  areAllRequiredFieldsFilled(): boolean {
    const controls = [
      'coaType', 'dueBy', 'customer', 'lob', 'service', 'product',
      'estAnnualVolume', 'chartererPartyForm', 'law',
      'manager', 'managerId', 'coaStartDate', 'coaEndDate'
    ];

    const allMandatoryFieldsFilled = controls.every(controlName => {
      const control = this.ccdForm.get(controlName);
      if (controlName === 'lob') {
        return control && Array.isArray(control.value) && control.value.length > 0 && control.valid;
      }
      return control && control.value && control.valid;
    });

    return allMandatoryFieldsFilled && this.ccdForm.valid;
  }

  //Next button click
  onSubmit() {
    console.log('submit 0', this.ccdForm.value);
    if (this.ccdForm.valid) {
      this.trimFormFields();
      console.log('submit 1');
      const formObj = this.deleteCcdRefField();
      const reqData = {
        contractData: formObj,
        type: "submit"
      };
      this.nextLoading = true;
      this.cdr.detectChanges();
      this.ccdService.createCcd(reqData).subscribe({
        next: ({ createdContract }: any) => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.SaveAsDraft.Success, Toasts.Actions.SaveAsDraft.Title);
          this.router.navigate(['/dashboard/create-ccd-step2'], { queryParams: { ccdNo: createdContract?.ccdNo } });
          this.nextLoading = false;
        },
        error: (error) => {
          console.error('Error creating/updating CCD:', error);
          this.nextLoading = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  cancelCcd() {
    this.router.navigate(['/dashboard']);
  }

  deleteCcdPopup() {
    this.openDeleteDraftPopup = true;
    document.body.classList.remove('bodyOverflow-hidden');
  }

  deleteCcd(event: any) {
    this.openDeleteDraftPopup = false;
    document.body.classList.add('bodyOverflow-scroll');
    console.log('deleteCcd', event?.isDeleteCcd, this.ccdForm.get('ccdNo')?.value);
    if (event?.isDeleteCcd) {
      if (this.ccdForm.get('ccdNo')?.value) {
        const reqData = {
          ccdNo: this.ccdForm.get('ccdNo')?.value,
          type: "discard",
          updateValue: ''
        }
        this.ccdService.updateContractStatus(reqData).subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.Delete.Success, Toasts.Actions.Delete.Title);
            this.router.navigate(['/dashboard']);
          },
          error: (error) => {
            console.error('Error deleting CCD:', error);
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Delete.Error);
          }
        });
      }
      else {
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.Delete.Success, Toasts.Actions.Delete.Title);
        this.router.navigate(['/dashboard']);
      }
    }
  }
}


