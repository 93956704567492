<section class="MainCard"> 
        <!-- Tabs  -->
        <div class="TabsHolder">
            <snl-page-tab [isSelected]="true">CCD creator/ Admin</snl-page-tab>
            <snl-page-tab>Legal</snl-page-tab>
            <snl-page-tab>Accounting</snl-page-tab>
            <snl-page-tab>Operations manager</snl-page-tab>
            <snl-page-tab>Tanker controller</snl-page-tab>
            <snl-page-tab>Demurrage</snl-page-tab>
            <snl-page-tab>Group risk</snl-page-tab> 
            <snl-page-tab>View only</snl-page-tab> 
        </div>

        
        <div class="AddNewUser">
            <snl-button [type]="'secondary'" [size]="'large'" (click)="open = true">
                <svg-icon src="assets/icons/plus.svg"></svg-icon>
                Add new user
            </snl-button>
        </div>

        <div class="ccd-table scroll">
            <snl-table class="TabelAddUser" [useTopScroll]="true">
                <snl-row class="bg-theader">
                    <snl-th class="HeightManager">
                        <span>Name/ Initial</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>Role</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>Department</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>Management</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>Owner</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>Admin</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>Dashboard access</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                        <span>View only</span>
                    </snl-th>
                    <snl-th class="HeightManager">
                         
                    </snl-th>
                </snl-row> 
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td> 
                        <snl-td>Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon (click)="DeleteUserModel = true" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row> 
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td> 
                        <snl-td>Global commercial manager Global commercial manager Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon (click)="DeleteUserModel = true" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td>  
                        <snl-td>Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td> 
                        <snl-td>Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon (click)="DeleteUserModel = true" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td>  
                        <snl-td>Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon (click)="DeleteUserModel = true" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td> 
                        <snl-td>Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon (click)="DeleteUserModel = true" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
                    <snl-row class="ColWidthMain">
                        <snl-td class="InitialName">Marlene Nicolaou- Boyer (MYE)</snl-td> 
                        <snl-td>Global commercial manager</snl-td> 
                        <snl-td>Commercial</snl-td> 
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>Yes</snl-td> 
                        <snl-td>Yes</snl-td>
                        <snl-td>No</snl-td> 
                        <snl-td><svg-icon (click)="DeleteUserModel = true" class="trash_icn" src="assets/icons/trash.svg"></svg-icon></snl-td>
                    </snl-row>
            </snl-table>
        </div>
</section>


<!-- Add New User Model -->
<snl-modal class="CommnModalChanges AddNewUserMdl" (modalClose)="open = false" [type]="'medium'" [open]="open"> 
    <div slot="heading">Add new user</div> 
    <div class="AddNewWrap" slot="modalContent">
        <ul>
            <li>  
                <!-- <snl-input
                [value]="''"
                [label]="'Ship name'"
                [isTrailingIconDisplayed]="true"
                [type]="'search-input'"
                formControlName="ShipName"
              ></snl-input> -->

              <div class="floating-input-container">
                <input type="text" id="name" class="floating-input" placeholder=" " />
                <label for="name" class="floating-label body-b1">Name/ Initial</label>
                <svg-icon  src="assets/icons/search_icn.svg"></svg-icon >
                <!-- <svg-icon  src="assets/icons/cross_icn.svg"></svg-icon >  -->

                    <div class="DropDownCstm">
                        <ul>
                            <li class="body-b1"><strong>Carl</strong> Smith (CAS)</li>
                            <li class="body-b1">Carrie White (CAW)</li>
                            <li class="body-b1">Connor Archie (CAR)</li>
                            <li class="body-b1">David Carson (DCA)</li>
                        </ul>
                    </div>
              </div>
            </li>
            <li>
                <snl-input
                [label]="'Role'"  
                ></snl-input>
            </li>
            <li>
                <snl-dropdown
                [label]="'Label'"
                [buttonPrimary]="'Apply'"
                [buttonSecondary]="'Reset'"
                [options]="[
                    {
                      id: 'ALB',
                      name: 'Alabama',
                    },
                    {
                      id: 'ALA',
                      name: 'Alaska',
                    },
                    {
                      id: 'VER',
                      name: 'Vermont',
                    },
                  ]"
                [multiple]="true"
              ></snl-dropdown>
            </li>
        </ul>
        <ul>
            <li>
                <span class="section-title">Management</span>
                <snl-switch 
                [activeText]="'YES'"
                [inActiveText]="'NO'" 
                ></snl-switch>
            </li>
            <li>
                <span class="section-title">Owner</span>
                <snl-switch 
                [activeText]="'YES'"
                [inActiveText]="'NO'" 
                ></snl-switch>
            </li>
            <li>
                <span class="section-title">Admin</span>
                <snl-switch 
                [activeText]="'YES'"
                [inActiveText]="'NO'" 
                ></snl-switch>
            </li>
            <li>
                <span class="section-title">Departrment</span>
                <snl-switch 
                [activeText]="'YES'"
                [inActiveText]="'NO'" 
                ></snl-switch>
            </li>
            <li>
                <span class="section-title">View only</span>
                <snl-switch 
                [activeText]="'YES'"
                [inActiveText]="'NO'" 
                ></snl-switch>
            </li>
        </ul>
    </div> 
    <div slot="modalFooter">
      <snl-button size="large">Save</snl-button>
      <snl-button size="large" type="secondary">Cancel</snl-button>
    </div>
</snl-modal>

<!-- Delete User Model -->
<snl-modal class="CommnModalChanges DeleteModel" (modalClose)="DeleteUserModel = false" [type]="'small'" [open]="DeleteUserModel">
    <div slot="heading">Delete user</div> 
    <div slot="modalContent">
         <p class="body-b1">
            Are you sure you want to delete 
            <strong>Marlene Nicolaou- Boyer (MYE)?</strong>
        </p>
    </div>
    <div slot="modalFooter">
        <snl-button type="secondary" (click)="DeleteUserModel = false" size="large">Cancel</snl-button>
        <snl-button size="large" class="DeleteClr">Yes, delete</snl-button>
    </div>
</snl-modal>