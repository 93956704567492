import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonModule, InputModule, ModalModule } from 'snl-complib';

@Component({
  selector: 'app-cancel-ccd-popup',
  standalone: true,
  imports: [ReactiveFormsModule, ModalModule, ButtonModule, InputModule, NgClass],
  templateUrl: './cancel-ccd-popup.component.html',
  styleUrl: './cancel-ccd-popup.component.scss'
})
export class CancelCcdPopupComponent implements OnInit {
  public cancelCcdForm = new FormGroup({
    cancellationNotes: new FormControl('', [Validators.required, Validators.maxLength(500)])
  });
  disableSaveButton: boolean = true;
  showErrorMessage: boolean = false;
  modalSettings: any;
  private cancellationNotesSubscriptions: Subscription = new Subscription();

  @Input() open: boolean = false;
  @Input() cancelCcdNo: string = '';
  @Input() cancelPopupSubmitLoading : boolean = false;
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  ngOnInit() {
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
    this.cancellationNotesSubscriptions.add(
      this.cancelCcdForm.get('cancellationNotes')?.valueChanges.subscribe(value => {
        this.showErrorMessage = value ? value.length > 500 : false;
      })
    );
  }

  closeModalPopUp() {
    this.onButtonClick.emit({})
  }

  saveAndCloseModalPopUp() {
    if (this.cancelCcdForm.valid) {
      this.onButtonClick.emit({
        cancellationNotes: this.cancelCcdForm.value.cancellationNotes
      });
    }
  }
  showError() {
    let field = 'cancellationNotes';
    if (this.cancelCcdForm.get(field)!.invalid && this.cancelCcdForm.get(field)!.touched && this.cancelCcdForm.get(field)!.hasError('maxlength')) {
      return "You cannot enter more than 500 characters in cancellation note."
    }
    return '';
  }

}
