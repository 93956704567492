<snl-modal class="CommnModalChanges CoomonModel400" (modalClose)="closeModalPopUp()" [settings]="modalSettings" [type]="'small'" [open]="open"> 
    <div slot="heading">Publish CCD</div> 
    <div slot="modalContent">
       <p class="body-b1">
        Are you sure you want to publish CCD#{{ccdNo}}?
       </p>
    </div> 
    <div slot="modalFooter">
      <snl-button class="BtnSkeleton" size="large" (click)="cancelEditCcdAndCloseModalPopUp()" [ngClass]="{'Stolt-Skeleton': publishPopupSubmitLoading}">Yes</snl-button>
      <snl-button size="large" type="secondary" (click)="closeModalPopUp()" [ngClass]="{'Stolt-Skeleton': publishPopupSubmitLoading}">Cancel</snl-button>
    </div>
</snl-modal>
