<snl-modal class="CommnModalChanges CancelCCD" (modalClose)="closeModalPopUp()" [settings]="modalSettings" [type]="'small'" [open]="open">
    <div slot="heading">Cancel CCD# {{cancelCcdNo}}</div>
    <div slot="headingDescription">Enter notes with reason for cancellation to proceed.</div>
    <div slot="modalContent">
        <form [formGroup]="cancelCcdForm" class="m-t-16">
            <snl-input [label]="'Cancellation notes'" formControlName="cancellationNotes" [errorMessage]="showError()"></snl-input>
        </form>
    </div>
    <div slot="modalFooter">
        <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': cancelPopupSubmitLoading}" size="large" [disabled]="!cancelCcdForm.value.cancellationNotes" (click)="saveAndCloseModalPopUp()">Submit</snl-button>
        <snl-button size="large" type="secondary" (click)="closeModalPopUp()">Cancel</snl-button>
    </div>
</snl-modal>