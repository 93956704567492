import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CcdService {

  constructor(
    private http: HttpClient
  ) { } 
  
  public enteredNote:string = '';
  public usersDataFromAd:any = [];
  public ccdApprovedStatus: any = {};
  private isCcdApproved = new BehaviorSubject<any>({});
  private reviewScreenAutoSaveMessage = new Subject<string>();
  private createCcdStep1ScreenAutoSaveMessage = new Subject<string>();
  private updateDocumentLegend = new BehaviorSubject<any>({});
  private departmentSpecificDocumentLegend = new BehaviorSubject<any>({});
  private updateNotesLegend = new BehaviorSubject<any>({});
  private departmentSpecificNotesLegend = new BehaviorSubject<any>({});

  setCcdApproveStatus(value: any) {
    this.ccdApprovedStatus = value;
    this.isCcdApproved.next(value);
  }
  getCcdApproveStatus() {
    return this.isCcdApproved;
  }
  
  setReviewScreenAutoSaveMessage(value: any) {
    this.reviewScreenAutoSaveMessage.next(value);
  }
  getReviewScreenAutoSaveMessage() {
    return this.reviewScreenAutoSaveMessage;
  }

  setCreateCcdStep1ScreenAutoSaveMessage(value: any) {
    this.createCcdStep1ScreenAutoSaveMessage.next(value);
  }
  getCreateCcdStep1ScreenAutoSaveMessage() {
    return this.createCcdStep1ScreenAutoSaveMessage;
  }

  //set and get department legend in upload component and its parent component
  setUpdatedDocumentLegend(value: any) {
    this.updateDocumentLegend.next(value);
  }
  getUpdatedDocumentLegend() {
    return this.updateDocumentLegend;
  }
  setDepartmentSpecificDocumentLegend(value: any) {
    this.departmentSpecificDocumentLegend.next(value);
  }
  getDepartmentSpecificDocumentLegend() {
    return this.departmentSpecificDocumentLegend;
  }
  
  //set and get notes legend in upload component and its parent component
  setUpdatedNotesLegend(value: any) {
    this.updateNotesLegend.next(value);
  }
  getUpdatedNotesLegend() {
    return this.updateNotesLegend;
  }
  setDepartmentSpecificNotesLegend(value: any) {
    this.departmentSpecificNotesLegend.next(value);
  }
  getDepartmentSpecificNotesLegend() {
    return this.departmentSpecificNotesLegend;
  }

  getLocalISOTime(now:any){
    const tzOffset = now.getTimezoneOffset() * 60000; 
    // Timezone offset in milliseconds
    const localTime = new Date(now.getTime() - tzOffset); 
    // Adjust to local time
    return localTime.toISOString().slice(0, -1); // Remove the "Z" to indicate local time 

  }

  fetchUser() {
    return this.http.get(`${environment.BASE_URL}fetch-user`);
  }
  
  fetchCCDList(status:any) {
    return this.http.post(`${environment.BASE_URL}fetch-dashboard-data`,status);
  }
  
  fetchUpcomingAlert() {
    return this.http.get(`${environment.BASE_URL}fetch-notifications`);
  }

  fetchCCDCount() {
    return this.http.get(`${environment.BASE_URL}fetch-contract-count`);
  }

  snoozeAlert() {
    return this.http.get(`${environment.BASE_URL}snooze-upcoming-notification`);
  }
  
  updateContractStatus(data:any) {
    return this.http.post(`${environment.BASE_URL}update-contract-status`, data);
  }
  
  initiateUpcomingContract(data:any) {
    return this.http.post(`${environment.BASE_URL}initiate-upcoming-contract`, data);
  }
  
  fetchLobList() {
    return this.http.get(`${environment.BASE_URL}fetch-lob-list`);
  }
  
  fetchCcdReference(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-reference`, data);
  }
  
  createCcd(data:any) {
    return this.http.post(`${environment.BASE_URL}create-ccd-draft`, data);
  }
  
  fetchCcdData(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-data`, data);
  }
  
  fetchCcdDataReview(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-review`, data);
  }
  
  createCcdStep2(data:any) {
    return this.http.post(`${environment.BASE_URL}create-departments-and-notes`, data);
  }
  
  createNotes(data:any) {
    return this.http.post(`${environment.BASE_URL}create-notes`, data);
  }

  fetchCcdNotes(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-notes`, data);
  }
  
  fetchCcdDepartments(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-departments`, data);
  }
  
  updateLegend(data:any) {
    return this.http.post(`${environment.BASE_URL}update-legend`, data);
  }
  
  submitCcd(data:any) {
    return this.http.post(`${environment.BASE_URL}update-ccd-to-active`, data);
  }
  
  markAsComplete(data:any) {
    return this.http.post(`${environment.BASE_URL}mark-department-complete`, data);
  }

  approveCcd(data:any) {
    return this.http.post(`${environment.BASE_URL}approve-ccd`, data);
  }
  
  fetchCcdDocuments(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-ccd-documents`, data);
  }

  updateAuditLogs(data:any) {
    return this.http.post(`${environment.BASE_URL}add-audit-logs`, data);
  }
  
  fetchUsersData(data:any) {
    return this.http.post(`${environment.BASE_URL}fetch-users-data`,data);
  }
  
  createUser(data:any) {
    return this.http.post(`${environment.BASE_URL}create-user`,data);
  }
  
  updateUser(data:any) {
    return this.http.post(`${environment.BASE_URL}update-user`,data);
  }
  
}
