import { CommonModule, Location } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, CheckboxModule, DatePickerModule, DropdownModule, FileUploadModule, InputModule, LinkModule, ModalModule, PageTitleModule } from 'snl-complib';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TYPEOFCOA, LAW } from 'src/app/constant/constant';
import { CcdService } from 'src/app/services/ccd.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { StorageService } from 'src/app/services/storage.service';
import { finalize } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteNewCcdCreationComponent } from 'src/app/components/delete-new-ccd-creation/delete-new-ccd-creation.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { Toasts } from 'src/app/constant/toast.messages';
import { UploadFilesComponent } from "../../components/upload-files/upload-files.component";

@Component({
  selector: 'app-create-ccd-step1',
  standalone: true,
  imports: [DeleteNewCcdCreationComponent, CommonModule, ReactiveFormsModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, ModalModule, FileUploadModule, CheckboxModule, UploadFilesComponent],
  templateUrl: './create-ccd-step1.component.html',
  styleUrls: ['./create-ccd-step1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCcdStep1Component {
  open: boolean = false;
  public ccdForm!: FormGroup;
  public savedFormObj: any = [];
  public isCcdRefCheckClicked: boolean = false;
  public coaTypeOptions: any;
  public lawOptions: any;
  public lobOptions: any = [];
  public managerOptions: any = [];
  private typeOfCoa: any = TYPEOFCOA;
  public law: any = LAW;
  public setDueDate: Date = new Date();
  public minDueDate: Date = new Date();
  public maxDueDate: Date = new Date();
  public setContractStartDate: Date = new Date();
  public setContractEndDate: Date = new Date();
  public minContractDate: Date = new Date();
  public maxContractDate: Date = new Date();
  public isCcdRefValidated: boolean | undefined = undefined;
  public openDeleteDraftPopup: boolean = false;
  public deleteCcdNo: string = '';
  public ccdNo: any;
  public coaTypeObj: any = [];
  public managerObj: any = [];
  public lawObj: any = [];
  public lobObj: any = [];
  public isCcdRefDisabled: boolean = true;
  public isAllowedToDeleteAndSaveExit: boolean = false;
  public isNewClauseChecked: boolean = false;
  public fetchCcdDataLoading: boolean = false;
  public nextLoading: boolean = false;
  public saveExitLoading: boolean = false;
  public autoSaveMessage: string = 'saved'; //not-saved, saved, saving
  public ownerAddedDocuments: any = [];

  constructor(
    private ccdService: CcdService,
    private validationService: ValidationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toasterService: ToasterService,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.uploadAutoSaveMessage();
    this.makeCcdForm();
    this.fetchTypeOfCoaOptions();
    this.fetchLawOptions();
    this.fetchLobOptions();
    this.fetchManagerOptions();
    this.route.queryParams.subscribe((params) => {
      this.ccdNo = params['ccdNo'];
      if (this.ccdNo) {
        this.fetchCCDData();
      }
      else{
        //set header data
        const headerData = {
          headerTitle: 'Start a new CCD',
          backButtonNavigateTo: 'dashboard',
          breadCrumbList: [
            { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
            { id: '3', label: 'Start a new CCD', isClickable: false }
          ]
        }
        this.navigationService.setHeaderData(headerData);
      }
      this.setDates();
    });
  }
  
  uploadAutoSaveMessage(){
    this.ccdService.getCreateCcdStep1ScreenAutoSaveMessage().subscribe((message: string) => {
      this.autoSaveMessage = message;
    });
  }

  makeCcdForm() {
    this.ccdForm = new FormGroup({
      ccdNo: new FormControl('', [Validators.minLength(3)]),
      coaType: new FormControl(''),
      dueBy: new FormControl(''),
      dueByOffset: new FormControl(''),
      customer: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      lob: new FormControl([]),
      service: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      product: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      estAnnualVolumeMin: new FormControl('0', [Validators.pattern(/^\d+$/), Validators.maxLength(15)]),
      estAnnualFreightMin: new FormControl('0', [Validators.pattern(/^\d+$/), Validators.maxLength(15)]),
      estAnnualVolumeMax: new FormControl('', [Validators.pattern(/^\d+$/), Validators.minLength(3), Validators.maxLength(15)]),
      estAnnualFreightMax: new FormControl('', [Validators.pattern(/^\d+$/), Validators.minLength(3), Validators.maxLength(15)]),
      chartererPartyForm: new FormControl('', [Validators.minLength(3), Validators.maxLength(36)]),
      law: new FormControl(''),
      manager: new FormControl(''),
      managerId: new FormControl(''),
      ccdRefField: new FormControl('', [Validators.minLength(7), Validators.maxLength(36)]),
      ccdRef: new FormControl(''),
      coaStartDate: new FormControl(''),
      coaStartDateOffset: new FormControl(''),
      coaEndDate: new FormControl(''),
      coaEndDateOffset: new FormControl(''),
      isNewClause: new FormControl(false)
    });
  }

  setDates() {
    this.setDueDateFunc();
    this.setContractDateFunc();
  }
  setDueDateFunc() {
    const today = new Date();
    this.minDueDate.setDate(today.getDate() + 6);
    this.maxDueDate.setDate(today.getDate() + 42);
    this.setDueDate.setDate(today.getDate() + 7);
    this.setDueDate.setHours(11);
    this.setDueDate.setMinutes(59);
    this.setDueDate.setSeconds(59);
    this.ccdForm.patchValue({
      dueBy: this.ccdService.getLocalISOTime(new Date(this.setDueDate)), 
      dueByOffset: new Date().getTimezoneOffset()
    });
  }
  setContractDateFunc() {
    const today = new Date();
    this.minContractDate.setDate(today.getDate());
    this.maxContractDate.setDate(today.getDate() + 1000);
    this.setContractStartDate.setDate(today.getDate());
    this.setContractEndDate.setDate(today.getDate() + 30);
    this.setContractStartDate.setHours(0);
    this.setContractStartDate.setMinutes(0);
    this.setContractStartDate.setSeconds(0);
    this.setContractEndDate.setHours(11);
    this.setContractEndDate.setMinutes(59);
    this.setContractEndDate.setSeconds(59);
    this.ccdForm.patchValue({
      coaStartDate: this.ccdService.getLocalISOTime(new Date(this.setContractStartDate)),
      coaEndDate: this.ccdService.getLocalISOTime(new Date(this.setContractEndDate)),
      coaStartDateOffset: new Date().getTimezoneOffset(),
      coaEndDateOffset: new Date().getTimezoneOffset()
    });
  }

  fetchTypeOfCoaOptions() {
    this.coaTypeOptions = Object.keys(this.typeOfCoa).map(key => ({
      id: key,
      name: this.typeOfCoa[key]
    }));
  }
  fetchLawOptions() {
    this.lawOptions = Object.keys(this.law).map(key => ({
      id: key,
      name: this.law[key]
    }));
  }
  fetchLobOptions() {
    this.ccdService.fetchLobList().subscribe({
      next: ({ lobList }: any) => {
        this.lobOptions = lobList;
      },
      error: (error) => {
        console.error('Error fetching Lob options:', error);
      }
    });
  }
  fetchManagerOptions() {
    const reqData = {
      type: "MANAGER"
    }
    this.ccdService.fetchUsersData(reqData).subscribe({
      next: ({ userData }: any) => {
        this.managerOptions = userData.map((user: any) => ({
          id: user?.userId,
          name: user.name
        }));
      },
      error: (error) => {
        console.error('Error fetching Manager options:', error);
      }
    });
  }

  populateCcdData(ccdObj: any) {
    if (ccdObj.coaType) {
      this.coaTypeObj = [{
        "id": ccdObj.coaType,
        "name": this.typeOfCoa[ccdObj.coaType]
      }];
    }
    if (ccdObj.law) {
      this.lawObj = [{
        "id": ccdObj.law,
        "name": this.law[ccdObj.law]
      }];
      console.log(this.lawObj)
    }
    if (ccdObj.lob.length > 0) {
      this.lobObj = ccdObj.lob.map((val: any) => ({ "id": val.split("(")[1].split(")")[0], "name": val }));
    }
    if (ccdObj.managerId) {
      this.managerObj = [{ "id": ccdObj.managerId, "name": ccdObj.manager }];
    }

    this.setContractEndDate = new Date(ccdObj.dueBy);
    if(!ccdObj?.initiatedFrom){
      this.setContractStartDate = new Date(ccdObj.coaStartDate);
      this.setContractEndDate = new Date(ccdObj.coaEndDate);
      this.ccdForm.patchValue({
        coaStartDate: this.ccdService.getLocalISOTime(new Date(this.setContractStartDate)),
        coaEndDate: this.ccdService.getLocalISOTime(new Date(this.setContractEndDate))
      });
    }
    else{
      this.setContractDateFunc();
    }
    this.isNewClauseChecked = ccdObj.isNewClause;
    this.isCcdRefValidated = ccdObj?.ccdRef ? true : undefined;
    let ccdRefField = ccdObj?.ccdRefField ? ccdObj.ccdRefField : '';

    let obj = {
      ccdNo: ccdObj.ccdNo,
      coaType: ccdObj.coaType,
      dueBy: this.ccdService.getLocalISOTime(new Date(ccdObj.dueBy)),
      customer: ccdObj.customer,
      lob: ccdObj.lob,
      service: ccdObj.service,
      product: ccdObj.product,
      estAnnualVolumeMin: ccdObj.estAnnualVolumeMin?.toString() ?? "0",
      estAnnualFreightMin: ccdObj.estAnnualFreightMin?.toString() ?? "0",
      estAnnualVolumeMax: ccdObj.estAnnualVolumeMax?.toString() ?? "",
      estAnnualFreightMax: ccdObj.estAnnualFreightMax?.toString() ?? "",
      chartererPartyForm: ccdObj.chartererPartyForm,
      law: ccdObj.law,
      manager: ccdObj.manager,
      managerId: ccdObj.managerId,
      ccdRefField: ccdRefField,
      ccdRef: ccdObj.ccdRef,
      isNewClause: ccdObj.isNewClause,
    };
    this.ccdForm.patchValue(obj);
    this.savedFormObj = this.ccdForm.value;
  }

  fetchCCDData() {
    this.fetchCcdDataLoading = true;
    this.ccdService.fetchCcdData({ "ccdNo": this.ccdNo }).subscribe({
      next: ({ ccdRes, ownerAddedDocuments }: any) => {
        if (ccdRes && ccdRes.length > 0) {
          this.populateCcdData(ccdRes[0]);
          this.isAllowedToDeleteAndSaveExit = true;
          this.setCreateCCDHeader();
        }
        this.ownerAddedDocuments = ownerAddedDocuments;
        this.fetchCcdDataLoading = false;
      },
      error: (error) => {
        console.error('Error fetching Manager options:', error);
        this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.err);
        if ([401, 404].includes(error?.status)) {
          this.router.navigate(['/dashboard']);
        }
        this.fetchCcdDataLoading = false;
      }
    });
  }
  
  setCreateCCDHeader(){
    //set header data
    const headerData = {
      headerTitle: 'Create CCD',
      backButtonNavigateTo: 'dashboard',
      breadCrumbList: [
        { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
        { id: '3', label: 'Create CCD', isClickable: false }
      ]
    }
    this.navigationService.setHeaderData(headerData);
  }

  applySelectedCoaType(event: any) {
    this.ccdForm.patchValue({ coaType: event?.selectedRecords?.id });
    this.autoSave('coaType');
  }

  resetCoaType() {
    this.ccdForm.patchValue({ coaType: '' });
    this.autoSave('coaType');
  }

  applySelectedLaw(event: any) {
    this.ccdForm.patchValue({ law: event?.selectedRecords?.id });
    this.autoSave('law');
  }

  resetLaw() {
    this.ccdForm.patchValue({ law: '' });
    this.autoSave('law');
  }

  applySelectedLob(event: any) {
    const selectedLobNames = event?.selectedRecords?.map((record: any) => record.name) || [];
    this.ccdForm.patchValue({ lob: selectedLobNames });
    this.autoSave('lob');
  }

  resetLob() {
    this.ccdForm.patchValue({ lob: [] });
    this.autoSave('lob');
  }

  applySelectedManager(event: any) {
    this.ccdForm.patchValue({ 'managerId': event?.selectedRecords?.id });
    this.ccdForm.patchValue({ 'manager': event?.selectedRecords?.name });
    this.autoSave('manager');
  }

  resetManager() {
    this.ccdForm.patchValue({ 'managerId': '' });
    this.ccdForm.patchValue({ 'manager': '' });
    this.autoSave('manager');
  }

  selectedDueDate(event: any) {
    event.setHours(23);
    event.setMinutes(59);
    event.setSeconds(59);
    this.ccdForm.patchValue({ dueBy: this.ccdService.getLocalISOTime(event), dueByOffset: new Date().getTimezoneOffset() });
    this.autoSave('dueBy');
  }

  selectedContractPeriod(event: any) {
    event?.startDate.setHours(0);
    event?.startDate.setMinutes(0);
    event?.startDate.setSeconds(0);
    event?.endDate.setHours(23);
    event?.endDate.setMinutes(59);
    event?.endDate.setSeconds(59);
    this.ccdForm.patchValue({ coaStartDate: this.ccdService.getLocalISOTime(event?.startDate), coaStartDateOffset: new Date().getTimezoneOffset() });
    this.ccdForm.patchValue({ coaEndDate: this.ccdService.getLocalISOTime(event?.endDate), coaEndDateOffset: new Date().getTimezoneOffset() });
    this.autoSave('coaStartDate');
  }

  withNewClauseClick(event: any) {
    this.ccdForm.patchValue({ isNewClause: event.target.checked });
    this.autoSave('isNewClause');
  }

  inputChanged(): void {
    const currentFormValues = this.ccdForm.value;
    const isFormChanged = JSON.stringify(this.savedFormObj) !== JSON.stringify(currentFormValues);

    if (isFormChanged) {
      this.autoSaveMessage = 'not-saved';
    } else {
      this.autoSaveMessage = 'saved';
    }
  }

  ccdRefChange() {
    console.log('ccdRefChange', this.ccdForm.get('ccdRefField')?.value, this.ccdForm.get('ccdRefField')?.valid);
    if (this.ccdForm.get('ccdRefField')?.invalid || (this.ccdForm.get('ccdRefField')?.value === '')) {
      this.isCcdRefDisabled = true;
    }
    else {
      this.isCcdRefDisabled = false;
    }
  }

  checkCcdRef() {
    this.trimFormFields();
    console.log('checkCcdRef', this.ccdForm.get('ccdRefField')?.valid);
    this.isCcdRefCheckClicked = true;
    const reqData = {
      ccdNo: this.ccdForm.get('ccdRefField')?.value
    }
    this.ccdService.fetchCcdReference(reqData).pipe(
      finalize(() => {
        this.isCcdRefCheckClicked = false;
      })
    ).subscribe({
      next: ({ ccdRes }: any) => {
        console.log('check validated')
        this.isCcdRefValidated = true;
        this.isCcdRefDisabled = true;
        this.ccdForm.get('ccdRefField')?.patchValue(ccdRes?.ccdNo + ' ' + ccdRes?.customer);
        this.ccdForm.get('ccdRef')?.patchValue(ccdRes?.ccdNo);
        this.autoSave('ccdRef');
      },
      error: (error) => {
        this.isCcdRefValidated = false;
        this.ccdForm.get('ccdRef')?.patchValue('');
        console.error('Error validating CCD:', error);
      }
    });
  }

  deleteCcdRefField() {
    const { ccdRefField, ...newObj } = this.ccdForm.value;
    return newObj;
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.ccdForm);
    return errorMsg;
  }

  trimFormFields() {
    Object.keys(this.ccdForm.controls).forEach(key => {
      const control = this.ccdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  autoSave(field: string): Promise<void> {
    this.trimFormFields();
    const fieldValue = this.ccdForm.get(field)?.value;
  
    if (field === 'document' || (this.ccdForm.valid && this.isNotEmptyStringOrArray(fieldValue) && this.isFieldValueChanged(field))) {
      let formObj: any = {};
      if (field === 'document') {
        formObj = {
          coaStartDate: this.ccdForm.value.coaStartDate,
          coaEndDate: this.ccdForm.value.coaEndDate,
          dueBy: this.ccdForm.value.dueBy,
          isNewClause: this.ccdForm.value.isNewClause,
        };
      } else {
        formObj = this.deleteCcdRefField();
      }
      const reqData = {
        contractData: formObj,
        type: 'saveDraft',
      };
  
      this.autoSaveMessage = 'saving';
  
      return new Promise((resolve, reject) => {
        this.ccdService.createCcd(reqData).subscribe({
          next: ({ createdContract }: any) => {
            this.ccdForm.get('ccdNo')?.patchValue(createdContract?.ccdNo);
            this.ccdNo = createdContract?.ccdNo;
  
            let queryParamsString: string = `?ccdNo=${createdContract.ccdNo}`;
            this.location.replaceState(`/dashboard/create-ccd-step1${queryParamsString}`);
            this.savedFormObj = this.ccdForm.value;
            this.isAllowedToDeleteAndSaveExit = true;
            this.setCreateCCDHeader();
            this.autoSaveMessage = 'saved';
  
            resolve(); // Resolve the promise after `ccdNo` is set
          },
          error: (error) => {
            this.autoSaveMessage = 'not-saved';
            console.error('Error creating/updating draft CCD:', error);
            reject(error); // Reject the promise on error
          },
        });
      });
    }
  
    return Promise.resolve(); // Default resolution for cases where the condition isn't met
  }

  @ViewChild(UploadFilesComponent) uploadFilesComponentRef!: UploadFilesComponent;

  handleStartUpload(): void {
      console.log('xyz1')
    this.autoSave('document').then(() => {
      console.log('xyz2')
      // Callback to trigger child component's startUploading
      const uploadComponent = this.uploadFilesComponentRef; // Reference to the child component
      if (uploadComponent) {
        uploadComponent.startUploading();
      }
    });
  }
  

  isFieldValueChanged(field: string) {
    if (field === 'lob') {
      return !this.areArraysEqual(this.ccdForm.get(field)?.value, this.savedFormObj[field]);
    } else {
      return this.ccdForm.get(field)?.value !== this.savedFormObj[field];
    }
  }

  isNotEmptyStringOrArray(fieldValue: any): boolean {
    if (typeof fieldValue === 'string') {
      return fieldValue !== '';
    } else if (Array.isArray(fieldValue)) {
      return fieldValue.length > 0;
    }
    return true;
  }

  areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    for (let i = 0; i < arr1?.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  saveAndExit() {
    console.log('save and exit 0', this.ccdForm);
    if (this.ccdForm.valid) {
      this.trimFormFields();
      console.log('save and exit 1');
      const formObj = this.deleteCcdRefField();
      const reqData = {
        contractData: formObj,
        type: "saveDraft"
      };
      this.saveExitLoading = true;
      this.cdr.detectChanges();
      this.ccdService.createCcd(reqData).subscribe({
        next: () => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
          this.storageService.putDataInStorage('selectedTab', 'draft');
          console.log('save and exit 2', this.storageService.getDataFromStorage('selectedTab'));
          this.router.navigate(['/dashboard']);
          this.saveExitLoading = false;
        },
        error: (error) => {
          console.error('Error updating draft CCD:', error);
          this.saveExitLoading = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  areAllRequiredFieldsFilled(): boolean {
    const controls = [
      'coaType', 'dueBy', 'customer', 'lob', 'service', 'product', 'estAnnualVolumeMax', 'estAnnualFreightMax', 'chartererPartyForm', 'law',
      'manager', 'managerId', 'coaStartDate', 'coaEndDate'
    ];

    const allMandatoryFieldsFilled = controls.every(controlName => {
      const control = this.ccdForm.get(controlName);
      if (controlName === 'lob') {
        return control && Array.isArray(control.value) && control.value.length > 0 && control.valid;
      }
      return control && control.value && control.valid;
    });

    return allMandatoryFieldsFilled && this.ccdForm.valid;
  }

  //Next button click
  onSubmit() {
    console.log('submit 0', this.ccdForm.value);
    if (this.ccdForm.valid) {
      this.trimFormFields();
      console.log('submit 1');
      const formObj = this.deleteCcdRefField();
      const reqData = {
        contractData: formObj,
        type: "submit"
      };
      this.nextLoading = true;
      this.cdr.detectChanges();
      this.ccdService.createCcd(reqData).subscribe({
        next: ({ createdContract }: any) => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.SaveAsDraft.Success, Toasts.Actions.SaveAsDraft.Title);
          const queryParamsObj:any = { ccdNo: createdContract?.ccdNo };
          this.router.navigate(['/dashboard/create-ccd-step2'], { queryParams: queryParamsObj });
          this.nextLoading = false;
        },
        error: (error) => {
          console.error('Error creating/updating CCD:', error);
          this.nextLoading = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  cancelCcd() {
    this.router.navigate(['/dashboard']);
  }

  deleteCcdPopup() {
    this.openDeleteDraftPopup = true;
  }

  deleteCcd(event: any) {
    this.openDeleteDraftPopup = false;
    console.log('deleteCcd', event?.isDeleteCcd, this.ccdForm.get('ccdNo')?.value);
    if (event?.isDeleteCcd) {
      if (this.ccdForm.get('ccdNo')?.value) {
        const reqData = {
          ccdNo: this.ccdForm.get('ccdNo')?.value,
          type: "discard",
          updateValue: ''
        }
        this.ccdService.updateContractStatus(reqData).subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.Delete.Success, Toasts.Actions.Delete.Title);
            this.router.navigate(['/dashboard']);
          },
          error: (error) => {
            console.error('Error deleting CCD:', error);
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Delete.Error);
          }
        });
      }
      else {
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.Delete.Success, Toasts.Actions.Delete.Title);
        this.router.navigate(['/dashboard']);
      }
    }
  }
}


