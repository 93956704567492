import { CommonModule, Location } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, CheckboxModule, DatePickerModule, DropdownModule, FileUploadModule, InputModule, LinkModule, ModalModule, PageTitleModule } from 'snl-complib';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { TYPEOFCOA, LAW, SERVICE } from 'src/app/constant/constant';
import { CcdService } from 'src/app/services/ccd.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { StorageService } from 'src/app/services/storage.service';
import { finalize, forkJoin, Subject, take } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteNewCcdCreationComponent } from 'src/app/components/delete-new-ccd-creation/delete-new-ccd-creation.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { Toasts } from 'src/app/constant/toast.messages';
import { UploadFilesComponent } from "../../components/upload-files/upload-files.component";
import { AuthService } from 'src/app/services/auth.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-ccd-step1',
  standalone: true,
  imports: [DeleteNewCcdCreationComponent, CommonModule, ReactiveFormsModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, ModalModule, FileUploadModule, CheckboxModule, UploadFilesComponent],
  templateUrl: './create-ccd-step1.component.html',
  styleUrls: ['./create-ccd-step1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCcdStep1Component {
  open: boolean = false;
  public ccdForm!: FormGroup;
  public savedFormObj: any = [];
  public isCcdRefCheckClicked: boolean = false;
  public coaTypeOptions: any;
  public lawOptions: any;
  public serviceOptions: any;
  public lobOptions: any = [];
  public managerOptions: any = [];
  public chartererPartyFormOptions: any = [];
  private typeOfCoa: any = TYPEOFCOA;
  public law: any = LAW;
  public service: any = SERVICE;
  public setDueDate: Date = new Date();
  public minDueDate: Date = new Date();
  public maxDueDate: Date = new Date();
  public setContractStartDate: Date = new Date();
  public setContractEndDate: Date = new Date();
  public minContractDate: Date = new Date();
  public maxContractDate: Date = new Date();
  public isCcdRefValidated: boolean | undefined = undefined;
  public openDeleteDraftPopup: boolean = false;
  public deleteCcdNo: string = '';
  public ccdNo: any;
  public coaTypeObj: any = [];
  public managerObj: any = [];
  public chartererPartyFormObj: any = [];
  public lawObj: any = [];
  public serviceObj: any = [];
  public lobObj: any = [];
  public isCcdRefDisabled: boolean = true;
  public isAllowedToDeleteAndSaveExit: boolean = false;
  public isNewClauseChecked: boolean = false;
  public fetchCcdDataLoading: boolean = false;
  public nextLoading: boolean = false;
  public saveExitLoading: boolean = false;
  public deleteLoading: boolean = false;
  public cancelReactivatedLoading: boolean = false;
  public autoSaveMessage: string = 'saved'; //not-saved, saved, saving
  public ownerAddedDocuments: any = [];
  public ccdRes: any;
  private lobDataFetched = new Subject<boolean>();
  private managersDataFetched = new Subject<boolean>();
  private ccdDataFetched = new Subject<boolean>();
  public fieldsLoading: boolean = true;
  private callCancelReactivateOnDestroy: boolean = false;

  constructor(
    private ccdService: CcdService,
    private validationService: ValidationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toasterService: ToasterService,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private encryptionService: EncryptionService
  ) { }

  ngOnInit() {
    this.uploadAutoSaveMessage();
    this.makeCcdForm();
    this.fetchTypeOfCoaOptions();
    this.fetchLawOptions();
    this.fetchServiceOptions();
    this.fetchLobOptions();
    this.fetchManagerOptions();
    this.route.queryParams.subscribe((params) => {
      this.ccdNo = params['ccdNo'];
      if (this.ccdNo) {
        this.fetchCCDData();
      }
      else {
        this.fieldsLoading = false;
        //set header data
        const headerData = {
          headerTitle: 'Start a new CCD',
          backButtonNavigateTo: 'dashboard',
          breadCrumbList: [
            { id: '1', label: 'Dashboard', navigateTo: 'dashboard', isClickable: true },
            { id: '3', label: 'Start a new CCD', isClickable: false }
          ]
        }
        this.navigationService.setHeaderData(headerData);
      }
      this.setDates();
    });
    this.executeCodeAfterDataFetched();
  }

  uploadAutoSaveMessage() {
    this.ccdService.getCreateCcdStep1ScreenAutoSaveMessage().subscribe((message: string) => {
      this.autoSaveMessage = message;
    });
  }

  makeCcdForm() {
    this.ccdForm = new FormGroup({
      ccdNo: new FormControl('', [Validators.minLength(3)]),
      coaType: new FormControl(''),
      dueBy: new FormControl(''),
      dueByOffset: new FormControl(''),
      customer: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      lob: new FormControl([]),
      service: new FormControl(''),
      product: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      estAnnualVolumeMin: new FormControl('0', [Validators.pattern(/^\d+$/), Validators.maxLength(15), this.minMaxValidator('estAnnualVolumeMin', 'estAnnualVolumeMax')]),
      estAnnualFreightMin: new FormControl('0', [Validators.pattern(/^\d+$/), Validators.maxLength(15), this.minMaxValidator('estAnnualFreightMin', 'estAnnualFreightMax')]),
      estAnnualVolumeMax: new FormControl('', [Validators.pattern(/^\d+$/), Validators.minLength(3), Validators.maxLength(15)]),
      estAnnualFreightMax: new FormControl('', [Validators.pattern(/^\d+$/), Validators.minLength(3), Validators.maxLength(15)]),
      chartererPartyForm: new FormControl(''),
      law: new FormControl(''),
      otherLaw: new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]),
      manager: new FormControl(''),
      managerId: new FormControl(''),
      ccdRefField: new FormControl('', [Validators.minLength(7), Validators.maxLength(108)]),
      ccdRef: new FormControl(''),
      coaStartDate: new FormControl(''),
      coaStartDateOffset: new FormControl(''),
      coaEndDate: new FormControl(''),
      coaEndDateOffset: new FormControl(''),
      isNewClause: new FormControl(false)
    });

    // call validation function minMaxValidator on entering data in max fields
    this.ccdForm.get('estAnnualVolumeMax')?.valueChanges.subscribe(() => {
      this.ccdForm.get('estAnnualVolumeMin')?.updateValueAndValidity();
    });
    this.ccdForm.get('estAnnualFreightMax')?.valueChanges.subscribe(() => {
      this.ccdForm.get('estAnnualFreightMin')?.updateValueAndValidity();
    });
  }

  minMaxValidator(minField: string, maxField: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minControl = control.root.get(minField);
      const maxControl = control.root.get(maxField);

      if (minControl && maxControl) {
        const minVal = Number(minControl.value);
        const maxVal = Number(maxControl.value);

        if (minVal > maxVal) {
          if (minField === 'estAnnualVolumeMin' && maxField === 'estAnnualVolumeMax') {
            return { 'minMaxVolume': true };
          }
          else if (minField === 'estAnnualFreightMin' && maxField === 'estAnnualFreightMax') {
            return { 'minMaxFreight': true };
          }
        }
      }

      return null;
    };
  }

  put0InMinFieldsIfEmpty() {
    const minFields = ['estAnnualVolumeMin', 'estAnnualFreightMin'];
    minFields.forEach(minField => {
      const control = this.ccdForm.get(minField);
      if (control && control.value === '') {
        control.setValue('0');
      }
    });
  }

  setDates() {
    this.setDueDateFunc();
    this.setContractDateFunc();
  }
  setDueDateFunc() {
    const today = new Date();
    this.minDueDate.setDate(today.getDate() + 6);
    this.maxDueDate.setDate(today.getDate() + 42);

    const setDueDate: Date = new Date();
    setDueDate.setDate(today.getDate() + 7);
    setDueDate.setHours(23);
    setDueDate.setMinutes(59);
    setDueDate.setSeconds(59);
    this.setDueDate = setDueDate;
    this.ccdForm.patchValue({
      dueBy: this.ccdService.getLocalISOTime(new Date(this.setDueDate)),
      dueByOffset: new Date().getTimezoneOffset()
    });
  }
  setContractDateFunc() {
    const today = new Date();
    this.minContractDate.setDate(today.getDate());
    this.maxContractDate.setDate(today.getDate() + 1000);

    const setContractStartDate: Date = new Date();
    const setContractEndDate: Date = new Date();
    setContractStartDate.setDate(today.getDate());
    setContractEndDate.setDate(today.getDate() + 30);
    setContractStartDate.setHours(0);
    setContractStartDate.setMinutes(0);
    setContractStartDate.setSeconds(0);
    setContractEndDate.setHours(23);
    setContractEndDate.setMinutes(59);
    setContractEndDate.setSeconds(59);
    this.setContractStartDate = setContractStartDate;
    this.setContractEndDate = setContractEndDate;
    this.ccdForm.patchValue({
      coaStartDate: this.ccdService.getLocalISOTime(new Date(this.setContractStartDate)),
      coaEndDate: this.ccdService.getLocalISOTime(new Date(this.setContractEndDate)),
      coaStartDateOffset: new Date().getTimezoneOffset(),
      coaEndDateOffset: new Date().getTimezoneOffset()
    });
  }

  fetchTypeOfCoaOptions() {
    this.coaTypeOptions = Object.keys(this.typeOfCoa).map(key => ({
      id: key,
      name: this.typeOfCoa[key]
    }));
  }
  fetchLawOptions() {
    this.lawOptions = Object.keys(this.law).map(key => ({
      id: key,
      name: this.law[key]
    }));
  }
  fetchServiceOptions() {
    this.serviceOptions = Object.keys(this.service).map(key => ({
      id: key,
      name: this.service[key]
    }));
  }
  fetchLobOptions() {
    this.ccdService.fetchLobList().subscribe({
      next: ({ lobList, charterparties }: any) => {
        this.lobOptions = lobList;
        this.chartererPartyFormOptions = charterparties;
        this.lobDataFetched.next(true);
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching Lob options:', error);
      }
    });
  }
  fetchManagerOptions() {
    const reqData = {
      type: "MANAGER",
      includeADUsers: false
    }
    this.ccdService.fetchUsersData(reqData).subscribe({
      next: ({ userData }: any) => {
        this.managerOptions = userData.map((user: any) => ({
          id: user?.userId,
          name: user.name
        }));
        this.managersDataFetched.next(true);
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching Manager options:', error);
      }
    });
  }

  executeCodeAfterDataFetched() {
    forkJoin([
      this.lobDataFetched.pipe(take(1)),
      this.managersDataFetched.pipe(take(1)),
      this.ccdDataFetched.pipe(take(1))
    ]).subscribe({
      next: () => {
        this.populateCcdData(this.ccdRes);
        this.fieldsLoading = false;
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching data:', error);
        this.fieldsLoading = false;
      }
    });

  }

  populateCcdData(ccdObj: any) {
    const mapObject = (key: keyof typeof ccdObj, target: keyof this, lookupSource?: Record<string, any>) => {
      if (ccdObj[key]) {
        const targetKey = target as keyof this;
        (this[targetKey] as { id: any; name: any }[]) = [
          { id: ccdObj[key], name: lookupSource?.[ccdObj[key]] || ccdObj[key] }
        ];
      }
    };
    mapObject("coaType", "coaTypeObj", this.typeOfCoa);
    mapObject("law", "lawObj", this.law);
    mapObject("service", "serviceObj", this.service);
    mapObject("chartererPartyForm", "chartererPartyFormObj");

    if (ccdObj.lob.length > 0) {
      this.lobObj = ccdObj.lob.map((val: any) => ({ "id": val, "name": val }));
    }
    if (ccdObj.managerId && this.isFetchedManagerPresentInOptions(ccdObj.managerId)) {
      this.managerObj = [{ "id": ccdObj.managerId, "name": ccdObj.manager }];
    }

    this.setDueDate = new Date(ccdObj.dueBy);
    if (!this.ccdRes?.initiatedFrom || this.ccdNo) {
      this.setContractStartDate = new Date(ccdObj.coaStartDate);
      this.setContractEndDate = new Date(ccdObj.coaEndDate);
      this.ccdForm.patchValue({
        coaStartDate: this.ccdService.getLocalISOTime(new Date(this.setContractStartDate)),
        coaEndDate: this.ccdService.getLocalISOTime(new Date(this.setContractEndDate))
      });
    }
    else {
      this.setContractDateFunc();
    }
    this.isNewClauseChecked = ccdObj.isNewClause;
    this.isCcdRefValidated = ccdObj?.ccdRef ? true : undefined;
    let ccdRefField = ccdObj?.ccdRefField ? ccdObj.ccdRefField : '';

    let obj = {
      ccdNo: ccdObj.ccdNo,
      coaType: ccdObj.coaType,
      dueBy: this.ccdService.getLocalISOTime(new Date(ccdObj.dueBy)),
      customer: ccdObj.customer,
      lob: ccdObj.lob,
      service: ccdObj.service,
      product: ccdObj.product,
      estAnnualVolumeMin: ccdObj.estAnnualVolumeMin?.toString() ?? "0",
      estAnnualFreightMin: ccdObj.estAnnualFreightMin?.toString() ?? "0",
      estAnnualVolumeMax: ccdObj.estAnnualVolumeMax?.toString() ?? "",
      estAnnualFreightMax: ccdObj.estAnnualFreightMax?.toString() ?? "",
      chartererPartyForm: ccdObj.chartererPartyForm,
      law: ccdObj.law,
      otherLaw: ccdObj.law === 'Other' ? ccdObj.otherLaw : '',
      manager: this.isFetchedManagerPresentInOptions(ccdObj.managerId) ? ccdObj.manager : '',
      managerId: this.isFetchedManagerPresentInOptions(ccdObj.managerId) ? ccdObj.managerId : '',
      ccdRefField: ccdRefField,
      ccdRef: ccdObj.ccdRef,
      isNewClause: ccdObj.isNewClause,
    };
    this.ccdForm.patchValue(obj);
    this.savedFormObj = this.ccdForm.value;
  }

  isFetchedManagerPresentInOptions(managerId: string) {
    return this.managerOptions.some((option: any) => option.id === managerId);
  }

  fetchCCDData() {
    this.fetchCcdDataLoading = true;
    this.ccdService.fetchCcdData({ "ccdNo": this.ccdNo }).subscribe({
      next: ({ ccdRes, ownerAddedDocuments }: any) => {
        if (ccdRes && ccdRes.length > 0) {
          if (ccdRes[0].ccdStatus !== 'draft' && !ccdRes[0]?.isReactivating) {
            this.router.navigate(['/dashboard']);
            return;
          }
          this.ccdRes = ccdRes[0];
          this.ccdDataFetched.next(true);
          this.isAllowedToDeleteAndSaveExit = true;
          this.setCreateCCDHeader();
        }
        this.ownerAddedDocuments = ownerAddedDocuments;
        this.fetchCcdDataLoading = false;
      },
      error: (error) => {
        console.error('Error fetching CCD data:', error);
        this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.err);
        if ([401, 404].includes(error?.status)) {
          this.router.navigate(['/dashboard']);
        }
        this.fetchCcdDataLoading = false;
      }
    });
  }

  setCreateCCDHeader() {
    //set header data
    const headerTitle = this.ccdRes?.isReactivating ? 'Reactivate CCD' : 'Create CCD';
    const headerData = {
      headerTitle: headerTitle,
      backButtonNavigateTo: 'dashboard',
      breadCrumbList: [
        { id: '1', label: 'Dashboard', navigateTo: 'dashboard', isClickable: true },
        { id: '3', label: headerTitle, isClickable: false }
      ]
    }
    this.navigationService.setHeaderData(headerData);
  }

  applySelectedCoaType(event: any) {
    this.ccdForm.patchValue({ coaType: event?.selectedRecords?.id });
    this.autoSave('coaType');
  }

  resetCoaType() {
    this.ccdForm.patchValue({ coaType: '' });
    this.autoSave('coaType');
  }

  applySelectedLaw(event: any) {
    this.ccdForm.patchValue({ law: event?.selectedRecords?.id });
    this.ccdForm.patchValue({ otherLaw: '' });
    this.autoSave('law');
  }

  resetLaw() {
    this.ccdForm.patchValue({ law: '' });
    this.ccdForm.patchValue({ otherLaw: '' });
    this.autoSave('law');
  }

  applySelectedService(event: any) {
    this.ccdForm.patchValue({ service: event?.selectedRecords?.id });
    this.autoSave('service');
  }

  resetService() {
    this.ccdForm.patchValue({ service: '' });
    this.autoSave('service');
  }

  applySelectedLob(event: any) {
    const selectedLobNames = event?.selectedRecords?.map((record: any) => record.name) || [];
    this.ccdForm.patchValue({ lob: selectedLobNames });
    this.autoSave('lob');
  }

  resetLob() {
    this.ccdForm.patchValue({ lob: [] });
    this.autoSave('lob');
  }

  applySelectedManager(event: any) {
    this.ccdForm.patchValue({ 'managerId': event?.selectedRecords?.id });
    this.ccdForm.patchValue({ 'manager': event?.selectedRecords?.name });
    this.autoSave('manager');
  }

  resetManager() {
    this.ccdForm.patchValue({ 'managerId': '' });
    this.ccdForm.patchValue({ 'manager': '' });
    this.autoSave('manager');
  }

  applySelectedChartererPartyForm(event: any) {
    this.ccdForm.patchValue({ chartererPartyForm: event?.selectedRecords?.id });
    this.autoSave('chartererPartyForm');
  }

  resetChartererPartyForm() {
    this.ccdForm.patchValue({ chartererPartyForm: '' });
    this.autoSave('chartererPartyForm');
  }

  selectedDueDate(event: any) {
    event.setHours(23);
    event.setMinutes(59);
    event.setSeconds(59);
    this.ccdForm.patchValue({ dueBy: this.ccdService.getLocalISOTime(event), dueByOffset: new Date().getTimezoneOffset() });
    this.autoSave('dueBy');
  }

  selectedContractPeriod(event: any) {
    event?.startDate.setHours(0);
    event?.startDate.setMinutes(0);
    event?.startDate.setSeconds(0);
    event?.endDate.setHours(23);
    event?.endDate.setMinutes(59);
    event?.endDate.setSeconds(59);
    this.ccdForm.patchValue({ coaStartDate: this.ccdService.getLocalISOTime(event?.startDate), coaStartDateOffset: new Date().getTimezoneOffset() });
    this.ccdForm.patchValue({ coaEndDate: this.ccdService.getLocalISOTime(event?.endDate), coaEndDateOffset: new Date().getTimezoneOffset() });
    this.autoSave('coaStartDate');
  }

  withNewClauseClick(event: any) {
    this.ccdForm.patchValue({ isNewClause: event.target.checked });
    this.autoSave('isNewClause');
  }

  inputChanged(): void {
    const currentFormValues = this.ccdForm.value;
    const isFormChanged = JSON.stringify(this.savedFormObj) !== JSON.stringify(currentFormValues);

    if (isFormChanged) {
      this.autoSaveMessage = 'not-saved';
    } else {
      this.autoSaveMessage = 'saved';
    }
  }

  ccdRefChange() {
    this.isCcdRefValidated = undefined;
    this.ccdForm.get('ccdRef')?.patchValue('');
    if (this.ccdForm.get('ccdRefField')?.invalid || (this.ccdForm.get('ccdRefField')?.value === '')) {
      this.isCcdRefDisabled = true;
    }
    else {
      this.isCcdRefDisabled = false;
    }
  }

  checkCcdRef() {
    this.trimFormFields();
    this.isCcdRefCheckClicked = true;
    const reqData = {
      ccdNo: this.ccdForm.get('ccdRefField')?.value
    }
    this.ccdService.fetchCcdReference(reqData).pipe(
      finalize(() => {
        this.isCcdRefCheckClicked = false;
      })
    ).subscribe({
      next: ({ ccdRes }: any) => {
        this.isCcdRefValidated = true;
        this.isCcdRefDisabled = true;
        this.ccdForm.get('ccdRefField')?.patchValue(ccdRes?.ccdNo + ' ' + ccdRes?.customer);
        this.ccdForm.get('ccdRef')?.patchValue(ccdRes?.ccdNo);
        this.autoSave('ccdRef');
      },
      error: (error) => {
        this.isCcdRefValidated = false;
        this.ccdForm.get('ccdRef')?.patchValue('');
        console.error('Error validating CCD:', error);
      }
    });
  }

  deleteCcdRefField() {
    const { ccdRefField, ...newObj } = this.ccdForm.value;
    return newObj;
  }

  addField(formObj: any) {
    return { ...formObj, isReactivating: this.ccdRes?.isReactivating };
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.ccdForm);
    return errorMsg;
  }

  trimFormFields() {
    Object.keys(this.ccdForm.controls).forEach(key => {
      const control = this.ccdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  autoSave(field: string): Promise<void> {
    this.put0InMinFieldsIfEmpty();
    this.trimFormFields();
    const fieldValue = this.ccdForm.get(field)?.value;

    if (field === 'document' || (this.ccdForm.valid && this.isNotEmptyStringOrArray(fieldValue) && this.isFieldValueChanged(field) && !this.ccdRes?.isReactivating)) {
      let formObj: any = {};
      if (field === 'document') {
        formObj = {
          coaStartDate: this.ccdForm.value.coaStartDate,
          coaEndDate: this.ccdForm.value.coaEndDate,
          dueBy: this.ccdForm.value.dueBy,
          coaStartDateOffset: this.ccdForm.value.coaStartDateOffset,
          coaEndDateOffset: this.ccdForm.value.coaEndDateOffset,
          dueByOffset: this.ccdForm.value.dueByOffset,
          isNewClause: this.ccdForm.value.isNewClause,
        };
      } else {
        formObj = this.deleteCcdRefField();
      }
      const reqData = {
        contractData: formObj,
        type: 'saveDraft',
      };

      this.autoSaveMessage = 'saving';

      return new Promise((resolve, reject) => {
        this.ccdService.createCcd(reqData).subscribe({
          next: ({ createdContract }: any) => {
            this.ccdForm.get('ccdNo')?.patchValue(createdContract?.ccdNo);
            this.ccdNo = createdContract?.ccdNo;

            let queryParamsString: string = `?ccdNo=${createdContract.ccdNo}`;
            this.location.replaceState(`/dashboard/create-ccd-step1${queryParamsString}`);
            this.savedFormObj = this.ccdForm.value;
            this.isAllowedToDeleteAndSaveExit = true;
            this.setCreateCCDHeader();
            this.autoSaveMessage = 'saved';

            resolve(); // Resolve the promise after `ccdNo` is set
          },
          error: (error) => {
            this.autoSaveMessage = 'not-saved';
            console.error('Error creating/updating draft CCD:', error);
            reject(error); // Reject the promise on error
          },
        });
      });
    }

    return Promise.resolve(); // Default resolution for cases where the condition isn't met
  }

  @ViewChild(UploadFilesComponent) uploadFilesComponentRef!: UploadFilesComponent;

  handleStartUpload(): void {
    this.autoSave('document').then(() => {
      // Callback to trigger child component's startUploading
      const uploadComponent = this.uploadFilesComponentRef; // Reference to the child component
      if (uploadComponent) {
        uploadComponent.startUploading();
      }
    });
  }


  isFieldValueChanged(field: string) {
    if (field === 'lob') {
      return !this.areArraysEqual(this.ccdForm.get(field)?.value, this.savedFormObj[field]);
    } else {
      return this.ccdForm.get(field)?.value !== this.savedFormObj[field];
    }
  }

  isNotEmptyStringOrArray(fieldValue: any): boolean {
    if (typeof fieldValue === 'string') {
      return fieldValue !== '';
    } else if (Array.isArray(fieldValue)) {
      return fieldValue.length > 0;
    }
    return true;
  }

  areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    for (let i = 0; i < arr1?.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  saveAndExit() {
    if (this.ccdForm.valid) {
      this.put0InMinFieldsIfEmpty();
      this.trimFormFields();
      let formObj = this.deleteCcdRefField();
      formObj = this.addField(formObj);
      const reqData = {
        contractData: formObj,
        type: "saveDraft"
      };
      this.saveExitLoading = true;
      this.cdr.detectChanges();
      this.callCancelReactivateOnDestroy = true;
      this.ccdService.createCcd(reqData).subscribe({
        next: () => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
          this.storageService.putDataInStorage('selectedTab', 'draft');
          this.router.navigate(['/dashboard']);
          this.saveExitLoading = false;
        },
        error: (error) => {
          if (error?.status === 424)
            this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
          else
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          this.saveExitLoading = false;
          this.cdr.detectChanges();
          console.error('Error updating draft CCD:', error);
        }
      });
    }
  }

  cancelReactivatedCcd() {
    const reqData = {
      ccdNo: this.ccdNo,
      type: "cancelReactivate",
      updateValue: ''
    }
    this.cancelReactivatedLoading = true;
    this.callCancelReactivateOnDestroy = true;
    this.ccdService.updateContractStatus(reqData).subscribe({
      next: () => {
        this.storageService.putDataInStorage('selectedTab', 'cancelled');
        this.router.navigate(['/dashboard']);
        this.cancelReactivatedLoading = false;
      },
      error: (error) => {
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        this.cancelReactivatedLoading = false;
        console.error('Error updating contract status:', error);
      }
    })
  }

  areAllRequiredFieldsFilled(): boolean {
    const controls = [
      'coaType', 'dueBy', 'customer', 'lob', 'service', 'product', 'estAnnualVolumeMin', 'estAnnualVolumeMax', 'estAnnualFreightMin', 'estAnnualFreightMax', 'chartererPartyForm', 'law',
      'manager', 'managerId', 'coaStartDate', 'coaEndDate'
    ];
    if (this.ccdForm.get('law')?.value === 'Other') {
      controls.push('otherLaw');
    }

    const allMandatoryFieldsFilled = controls.every(controlName => {
      const control = this.ccdForm.get(controlName);
      if (controlName === 'lob') {
        return control && Array.isArray(control.value) && control.value.length > 0 && control.valid;
      }
      return control && control.value && control.valid;
    });

    return allMandatoryFieldsFilled && this.ccdForm.valid;
  }

  //Next button click
  onSubmit() {
    if (this.ccdForm.valid) {
      this.put0InMinFieldsIfEmpty();
      this.trimFormFields();
      let formObj = this.deleteCcdRefField();
      formObj = this.addField(formObj);
      const reqData = {
        contractData: formObj,
        type: "submit",
      };
      this.nextLoading = true;
      this.cdr.detectChanges();
      this.callCancelReactivateOnDestroy = true;
      this.ccdService.createCcd(reqData).subscribe({
        next: ({ createdContract }: any) => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.SaveAsDraft.Success, Toasts.Actions.SaveAsDraft.Title);
          const queryParamsObj: any = { ccdNo: createdContract?.ccdNo };
          this.router.navigate(['/dashboard/create-ccd-step2'], { queryParams: queryParamsObj });
          this.nextLoading = false;
        },
        error: (error) => {
          if (error?.status === 424)
            this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
          else
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          this.nextLoading = false;
          this.cdr.detectChanges();
          console.error('Error creating/updating CCD:', error);
        }
      });
    }
  }

  cancelCcd() {
    this.router.navigate(['/dashboard']);
  }

  deleteCcdPopup() {
    this.openDeleteDraftPopup = true;
  }

  deleteCcd(event: any) {
    this.openDeleteDraftPopup = false;
    if (event?.isDeleteCcd) {
      if (this.ccdForm.get('ccdNo')?.value) {
        const reqData = {
          ccdNo: this.ccdForm.get('ccdNo')?.value,
          type: "discard",
          updateValue: ''
        }
        this.deleteLoading = true;
        this.callCancelReactivateOnDestroy = true;
        this.ccdService.updateContractStatus(reqData).subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
            this.router.navigate(['/dashboard']);
            this.deleteLoading = false;
          },
          error: (error) => {
            console.error('Error deleting CCD:', error);
            if (error?.status === 424)
              this.toasterService.openToastCcd(Toasts.Types.Error, error?.error?.reason);
            else
              this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DeleteCcd.Error);
            this.deleteLoading = false;
          }
        });
      }
      else {
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
        this.router.navigate(['/dashboard']);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.ccdRes?.isReactivating && !this.callCancelReactivateOnDestroy) {
      this.cancelReactivatedCcd();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    // Check if it's NOT a refresh
    if (!this.isRefresh() && this.ccdRes?.isReactivating) {
      const reqData = {
        ccdNo: this.ccdNo,
        type: "cancelReactivate",
        updateValue: ''
      }
      const apiUrl = `${environment.BASE_URL}update-contract-status`;
      const match = this.authService.UserObj.displayName.match(/\(([^)]+)\)/);
      const userInitials = match ? match[1] : this.authService.UserObj.displayName;
      const userInfo: any = {
        userId: this.authService.UserObj.id,
        userEmail: this.authService.UserObj.userPrincipalName,
        userName: userInitials,
        name: this.authService.UserObj.displayName,
        userRole: this.authService.backendUserObj.role,
      };
      const encryptedUserInfo = this.encryptionService.encryptionAES(JSON.stringify(userInfo));
      alert(apiUrl);
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'userAuth': encryptedUserInfo,
        },
        body: JSON.stringify(reqData),
        keepalive: true,
      });
    }
  }
  private isRefresh(): boolean {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length > 0) {
      const navigationType = (navigationEntries[0] as PerformanceNavigationTiming).type;
      return navigationType === 'reload';
    }
    return false;
  }

}


