import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { first, map } from 'rxjs/operators';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.getBackendUserData().pipe(
    first(user => user !== null), // Wait for the first non-null value
    map(user => {
      if (user && (user.role === '1' || user.role === '2')) {
        return true;
      } else {
        router.navigate(['dashboard']);
        return false;
      }
    })
  );
};