<div class="ckeditorWrapper">
  <div class="ChatMsg __commentUserMn scroll" [ngClass]="from === 'review' ? 'reviewChatMsg' : 'notesPopupChatMsg'">
  @for (note of ccdNotes; track $index) {
    <ul> 
      <li>
          <snl-profile-image [profileInitial]="fetchUserInitials(note?.userName)" [size]="'small'"></snl-profile-image>
      </li>
      <li>
          <p class="body-b2 UserName">{{fetchUserName(note?.userName)}}</p> 
          <span class="caption">{{getFormattedDateTime(note?.createdAt)}}</span>
          <p class="body-b1 commentUser" [innerHTML]="saferHtml(note?.notes)"></p>
      </li>
    </ul>
  }
  </div>

  <div *ngIf="accessOfNotesEditor">
    <div class="main-container">
      <div class="editor-container editor-container_classic-editor focused_container_editor" #editorContainerElement>
        <div class="editor-container__editor" [ngClass]="!collapsedToggle ? 'OptionsHideEdtr' : ''">
          <svg-icon (click)="toggleClick()" class="df-c chevronToggle" src="assets/icons/chevron.svg"></svg-icon>            
          <div #editorElement>
            <ckeditor [editor]="Editor" [config]="config" *ngIf="isLayoutReady" (change)="checkChanges($event)"/>
            <button class="SendMsg section-title" type="button" (click)="addNotes()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>