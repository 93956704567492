import { Component, Input } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-no-data-found-screen',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './no-data-found-screen.component.html',
  styleUrl: './no-data-found-screen.component.scss'
})
export class NoDataFoundScreenComponent {
  @Input() selectedTab: string = '';

}
