import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule, ModalModule } from 'snl-complib';

@Component({
  selector: 'app-delete-new-ccd-creation',
  standalone: true,
  imports: [ModalModule,ButtonModule, NgClass],
  templateUrl: './delete-new-ccd-creation.component.html',
  styleUrl: './delete-new-ccd-creation.component.scss'
})
export class DeleteNewCcdCreationComponent {
  disableSaveButton:boolean = true;
  modalSettings: any;

  @Input() open: boolean = false;
  @Input() deletePopupDeleteLoading: boolean =false;
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  ngOnInit(){
    this.modalSettings = { showCloseIcon: true, backdropClick: false };
  }

  closeModalPopUp() {
    this.onButtonClick.emit({}) 
  }

  deleteAndCloseModalPopUp() {
      this.onButtonClick.emit({
        isDeleteCcd: true
      });
  }
}

